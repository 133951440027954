import { Platform } from 'react-native';
import * as Sentry from 'sentry-expo';

const catchErrorForSentry = (error: any)=>{
    console.log("Error name: ", error.name);
    console.log("Error code: ", error.code);
    console.log("Error message: ", error.message);
    console.log("Error stack: ", error.stack);
    if (Platform.OS === 'web') {
        // Use browser specific methods
        Sentry.Browser.captureException(error);
      } else {
        // Use native specific methods
        Sentry.React.captureException(error);
      }
}

export default catchErrorForSentry