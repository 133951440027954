    import firebase from 'firebase/compat/app';
    import 'firebase/compat/auth';
    import 'firebase/compat/database';
    import ApiKeys from './Constants/ApiKeys';
    import isObjEmpty from './helpers/isObjEmpty';
import catchErrorForSentry from './helpers/catchSentryError';
  
    

    class Fire {


    constructor() {
        this.init();
    //   this.checkAuth();
    }

    init = () => {
        if (!firebase.apps.length) {
            firebase.initializeApp(ApiKeys.FirebaseConfig);
        }
    };

    async createUserWithEmail(email: string, password: string) {
        try {
            const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
            console.log('User created:', userCredential.user);
            return userCredential;
        } catch (error) {
            console.error('Error creating user:', error);
            console.log("Error creating user with email: ", JSON.stringify(error)); 
            catchErrorForSentry(error);
            throw error;
        }
    }

    // Function to send a verification code to the given phone number
    // sendVerificationCode(phoneNumber: string) {
    //     const appVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
    //         size: 'invisible',
    //     });
    //     return firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
    // }



    // Function to link the phone number to the currently signed-in user
    async linkPhoneNumberToUser(confirmationResult: firebase.auth.ConfirmationResult, verificationCode: string, phoneNumber: string) {
        try {
            const userCredential: any = await confirmationResult.confirm(verificationCode);
            const currentUser: any = firebase.auth().currentUser;
            const linkedUserCredential: any = await currentUser?.linkWithCredential(userCredential.credential) ?? null;
            if(!linkedUserCredential){
                console.error('Error linking phone number. Phone number not found in the user data.');
                throw new Error("Error linking phone number");
            }
            else if (linkedUserCredential.user.phoneNumber ?? null === phoneNumber) {
                console.log('Phone number linked successfully:', linkedUserCredential.user.phoneNumber);
              }
        } catch (error) {
            console.error('Error linking phone number:', error);
            throw error;
        }
    }


    // initFirestore = ()=>{
    //   firebase.firestore();
    // }

    //   checkAuth = () => {
    //       firebase.auth().onAuthStateChanged(user => {
    //           if (!user) {
    //               firebase.auth().signInAnonymously();
    //           }
    //       });
    //   };

    //   send = messages => {
    //       messages.forEach(item => {
    //           const message = {
    //               text: item.text,
    //               timestamp: firebase.database.ServerValue.TIMESTAMP,
    //               user: item.user
    //           };

    //           this.db.push(message);
    //       });
    //   };

    //   parse = message => {
    //       const { user, text, timestamp } = message.val();
    //       const { key: _id } = message;
    //       const createdAt = new Date(timestamp);

    //       return {
    //           _id,
    //           createdAt,
    //           text,
    //           user
    //       };
    //   };

    //   get = callback => {
    //       this.db.ref("messages").on("child_added", snapshot => callback(this.parse(snapshot)));
    //   };

    //   off() {
    //       this.db.off();
    //   }

      getFirebase(){
        return firebase;
      }

    //   getAllEvents(callback){
    //     this.db.ref(`events/`).once('value').then((data)=>{
    //       return callback(data.toJSON());
    //     })
    //   }

    //   getAllUsers(callback){
    //     this.db.ref(`users/`).once('value').then((data)=>{
    //       return callback({userList: data.toJSON()});
    //     })
    //   }

    //   getSpecificUsers(specificUsers, callback){
    //     let specificUsersDataObject = {};

    //     for (let i = 0; i < specificUsers.length; i++){
    //       this.db.ref('users/' + specificUsers[i]).once('value').then((specificUserData)=>{
    //         let data = specificUserData.toJSON()
    //           specificUsersDataObject[`${data.uid}`] = data;
    //       })
    //     }

    //     return callback(specificUsersDataObject);


    //   }

    //   asyncQueryData = (dataName)=>{

    //       return new Promise((resolve)=>{

    //           this.db.ref(`${dataName}`).once('value').then((data) => {

    //             resolve(data.toJSON());
    //           });
    //       });
    //   }

    //   asyncQueryDataByID = (dataName, id)=>{

    //       return new Promise((resolve)=>{

    //           this.db.ref(`${dataName}/${id}`).once('value').then((data) => {

    //             resolve(data.toJSON());
    //           });
    //       });
    //   }

    //   queryUserById(userId, callback){
    //     this.db.ref("users/" + userId).once('value').then((data) => {

    //       return callback({userData: data.toJSON()});
    //     })
    //   }

    //   queryUserByIdForMessagerScreen(userId, callback){
    //     this.db.ref("users/" + userId).once('value').then((data) => {

    //       return callback((prevState)=>{
    //         prevState.currentUserData = JSON.parse(data.toJSON());
    //       });
    //     })
    //   }

    //   getCurrentUser() {
    //     firebase.auth().onAuthStateChanged(function(user) {
    //         if (user) {
    //           return user;
    //         } else {
    //           return false;
    //         }
    //       });
    //   }

    //   get db() {
    //       return firebase.database();
    //   }

    //   get uid() {
    //       return (firebase.auth().currentUser || {}).uid;
    //   }
    }

    export default new Fire();

