import React, { useState, useEffect, useRef } from 'react';
import { Image, Linking, Platform, ScrollView, Text, TextInput, Touchable, View } from 'react-native';
import AccountChoosePictures from '../components/AccountChoosePictures';
import CustomModal from '../components/CustomModal';
// import Slideshow from '../components/Slideshow';
import TouchableContainer from '../components/TouchableContainer';
import { useSelector, useDispatch } from 'react-redux';
import { COLORS, GOOGLE_MAPS_API_KEY, S3_URL, SCREEN_SIZE, errorMessages, regexPatterns } from '../Constants';
import { RootState } from '../store';
// import Slideshow from '../components/Slideshow';
import Fire from '../Fire';
import Swiper from 'react-native-web-swiper';
// import MapView from 'react-native-map-clustering';
// import MapView from 'react-native-maps';@alamoweb/react-native-web-mapview
import MapView, { Marker } from 'react-native-maps';
import getLatLngFromAddress from '../helpers/getLatLngFromAddress';
import getMostAccurateLocation from '../helpers/getMostAccurateLocation';
import Card from '../components/Card';
import * as Location from 'expo-location';
import { MaterialCommunityIcons, MaterialIcons, AntDesign  } from '@expo/vector-icons'; 
import { FontAwesome5 } from '@expo/vector-icons'; 
import { SimpleLineIcons } from '@expo/vector-icons'
import { Entypo } from '@expo/vector-icons'; 
import CustomButton from '../components/CustomButton';
import UploadPictureModal from '../components/UploadPictureModal';
import confirmDecision from '../helpers/confirmDecision';
import AccountSelectProfilePicture from '../components/AccountSelectProfilePicture';
import { setUserData, updateUserDataProperty } from '../store/slices/userData';
import ValidateInput from '../components/ValidateInput';
import updateUserDatabaseKey from '../helpers/updateUserDatabaseKey';
import isUsernameTaken from '../helpers/isUsernameTaken';
import Input from '../components/Input';
import isObjEmpty from '../helpers/isObjEmpty';
import addCopiesToArray from '../helpers/addCopiesToArray';
import arrayToObject from '../helpers/arrayToObject';
import isUrlValid from '../helpers/isUrlValid';
import deleteImageFromCache from '../helpers/deleteImageFromCache';
import catchErrorForSentry from '../helpers/catchSentryError';
import updateCurrentUserEmail from '../helpers/updateCurrentUserEmail';
import ReauthCurrentUserModal from '../components/ReuathCurrentUserModal';
import VerifyPhoneNumberModal from '../components/VerifyPhoneNumberModal';
import { FirebaseRecaptchaVerifierModal } from 'expo-firebase-recaptcha';
import formatPhoneNumber from '../helpers/formatPhoneNumber';
import ensureProtocol from '../helpers/ensureProtocol';

// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
const firebase = Fire.getFirebase();
// import Slideshow from 'react-native-slideshow';
// import { Slideshow } from 'rn-slideshow';

const AccountProfile = (props: any)=>{

    const recaptchaVerifier = useRef();
    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);

    const defaultUploadedPictures: string[] = (()=>{
        return userData.pictureKeys ? Object.values(userData.pictureKeys).map((key)=>{return `${S3_URL}/${key}`}) : [];
    })()
    const defaultEntertainmentPictures: string[] = (()=>{
        return userData.entertainmentPictureKeys ? Object.values(userData.entertainmentPictureKeys).map((key)=>{return `${S3_URL}/${key}`}) : [];
    })()
    const [uploadedPictures, setUploadedPictures] = useState<string[]>(defaultUploadedPictures);
    const [uploadedEntPictures, setUploadedEntPictures] = useState<string[]>(defaultEntertainmentPictures);

    const [edittingMode, setEdittingMode] = useState
    <
        "" | "coverPictures" |
        "selectProfilePicture" |
        "nameAndAddressChange" |
        "dailySpecialsChange" |
        "weeklySpecialsChange" |
        "monthlySpecialsChange" | 
        "dealsChange" | 
        "entertainmentChange"
    >("")
    const [showModal, setShowModal] = useState(false)
    const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
    const [showReauthModal, setShowReauthModal] = useState(false);
    const [showVerifyPhoneNumberModal, setShowVerifyPhoneNumberModal] = useState(false);
    const [monthlySpecialsImageKey, setMonthlySpecialsImageKey] = useState<string>(userData.monthlySpecialsImageKey);

    const [profilePictureKey, setProfilePictureKey] = useState(userData.profilePictureKey);
    const [state, setState] = useState({
        accountName: userData.displayName,
        email: userData.email,
        address: userData.address,
        website: userData.website || "",
        phoneNumber: null,
        usernameError: false,
        coords: userData.coords,
        facebookUrl: userData.facebookUrl || "",
        instagramUrl: userData.instagramUrl || "",
    });


    const [dailySpecials, setDailySpecials] = useState(isObjEmpty({...userData.dailySpecials}) ? {0: "", 1: "", 2: ""} : {...userData.dailySpecials});
    const [weeklySpecials, setWeeklySpecials] = useState(isObjEmpty({...userData.weeklySpecials}) ? {0: "", 1: "", 2: ""}  : {...userData.weeklySpecials});
    const [monthlySpecials, setMonthlySpecials] = useState(isObjEmpty({...userData.monthlySpecials}) ? {0: "", 1: "", 2: ""}  : {...userData.monthlySpecials});

    const [dealOne, setDealOne] = useState(isObjEmpty({...userData.dealOne}) ? {title: "", details: "", link: ""} : {...userData.dealOne});
    const [dealTwo, setDealTwo] = useState(isObjEmpty({...userData.dealTwo}) ? {title: "", details: "", link: ""} : {...userData.dealTwo});

    
    useEffect(()=>{

        let phoneNumberRef = firebase.database().ref(`phoneNumbers/${userData.uid}`)
        phoneNumberRef.on("value", (data)=>{
            const phoneNumber = data.val();
            if(phoneNumber) setState((prevState)=>{prevState.phoneNumber = phoneNumber; return prevState;});
        })

        let entPictureKeysRef = firebase.database().ref(`publicUserData/${userData.uid}/entertainmentPictureKeys`)
        entPictureKeysRef.on("value", (data)=>{
            const pictureKeys = data.val();
            if(pictureKeys){
                const pictureData = Object.values(pictureKeys).map((key)=>{return `${S3_URL}/${key}`});
                setUploadedEntPictures(pictureData)
            }
            else setUploadedEntPictures([])
        })
        
        let pictureKeysRef = firebase.database().ref(`publicUserData/${userData.uid}/pictureKeys`)
        pictureKeysRef.on('value', (data)=>{
            const pictureKeys = data.val();
            if (pictureKeys) {
                const pictureData = Object.values(pictureKeys).map((key)=>{return `${S3_URL}/${key}`});
                setUploadedPictures(pictureData);
            }
            else setUploadedPictures([])
        })
        return () =>{
            pictureKeysRef.off();
            entPictureKeysRef.off();
        }

    }, [])

    const onModifyPictures = ()=>{
        setShowModal(true);
        setEdittingMode("coverPictures");
    }

    const onModifyEntertainmentPictures = ()=>{
        setShowModal(true);
        setEdittingMode("entertainmentChange")
    }

    const getTitle = ()=>{
        if(edittingMode === "coverPictures") return <Text style={{fontSize: 20}}>Upload your pictures here</Text>;
        if(edittingMode === "selectProfilePicture") return <Text style={{fontSize: 20}}>Select your profile picture</Text>
        if(edittingMode === "nameAndAddressChange") return ''
    }

    const onChangeAddress = async ()=>{
        let locationCoords  = await getLatLngFromAddress(state.address);
        if(locationCoords === null) return;

        const { address } = state;
        console.log("Location Coords: ", locationCoords);
        // setLocationVerified(true);
        // setEventData((prevState)=>{
        //     prevState.location = {coords: getMostAccurateLocation(locationCoords)};
        //     return prevState;
        // })
        // console.log(Location Coords: locationCoords);
        // alert(i18n.t('LocationVerified'));

        //Updating database
        updateUserDatabaseKey(userData.uid, 'address', address);
        updateUserDatabaseKey(userData.uid, 'coords', locationCoords);
        //Updating Local Data
        dispatch(updateUserDataProperty({property: 'address', value: address}));
        dispatch(updateUserDataProperty({property: 'coords', value: locationCoords}));

        alert("Your address has been updated");
        // setShowModal(false);
        // setEdittingMode("")
    }

    const onChangeUsername = ()=>{

    }

    const onChangeAccountName = ()=>{
        const {accountName} = state;
        // console.log("accountName: ", accountName)
        // return;
        if(userData.displayName === accountName) return alert("You already have that name!");
        //Updating database
        updateUserDatabaseKey(userData.uid, 'displayName', accountName);
        //Updating Local Data
        dispatch(updateUserDataProperty({property: 'displayName', value: accountName}));

        //TODO: Change username function - Maybe could allow this as well? - Future feature!
        // isUsernameTaken(accountName)
        // .then((response: any)=>{
        //     const isUsernameTaken = JSON.parse(response);
        //     if(isUsernameTaken === null) return;
        //     if(isUsernameTaken) return alert("Sorry, that name is taken!");
        //     // console.log(`Changing username from ${userData.displayName} to ${accountName}`);
        //     //Updating database
        //     updateUserDatabaseKey(userData.uid, 'username', accountName);
        //     //Updating Local Data
        //     dispatch(updateUserDataProperty({property: 'username', value: accountName}));
        // })
        // .catch((error)=>{
        //     alert("Error checking if that name was taken, please try again")
        //     console.log("Error checking if username was taken (Account profile): ", error);
        //     console.log("Error checking if username was taken (Account Profile): ", JSON.stringify(error)); 
        //     catchErrorForSentry(error);
        // })
    }

    // const sendVerification = (phoneNumber: string) => {
    //     console.log("Set verifying recaptcha as true");
    //     console.log("Attempting to verify number: ", phoneNumber);
    //     setVerifyingRecaptcha(true);
    //     return;
    // }
    
    const onChangePhoneNumber = ()=>{
        setShowModal(false);
        setShowVerifyPhoneNumberModal(true);
        // console.log("Set verifying recaptcha as true");
        // console.log("Attempting to verify number: ", state.phoneNumber);
        // setVerifyingRecaptcha(true);
        
    }

    const onVerifyPhoneNumberSuccess = ()=>{
        
    }

    const onReauthSuccess = async ()=>{
        await updateCurrentUserEmail(
            state.email,
            ()=>alert("Your email has been updated!"), //On Success
            onChangeEmailFailure //On Failure
        )
    }

    const onChangeEmailFailure = (error: any)=>{
        console.log("Error code: ", error.code);
        if(error.code === 'auth/requires-recent-login'){
            setShowModal(false);
            setShowReauthModal(true);
        }
        else{
            catchErrorForSentry(error);
            alert("Sorry, we failed to update your email - Please try again");
        }
    }

    const onChangeEmailSuccess = (success: any)=>{
        //Updating database
        updateUserDatabaseKey(userData.uid, 'email', state.email);
        //Updating Local Data
        dispatch(updateUserDataProperty({property: 'email', value: state.email}));
    }

    const onChangeEmail = async ()=>{
        await updateCurrentUserEmail(
            state.email,
            onChangeEmailSuccess, //On Success
            onChangeEmailFailure //On Failure
        )
    }

    const onChangeWebsite = ()=>{
        const website = ensureProtocol(state.website);
        //Updating database
        updateUserDatabaseKey(userData.uid, 'website', website);
        //Updating Local Data
        dispatch(updateUserDataProperty({property: 'website', value: website}));
        // alert("Your website has been updated");
    }
    const onChangeFacebookUrl = ()=>{
        const website = ensureProtocol(state.facebookUrl);
        //Updating database
        updateUserDatabaseKey(userData.uid, 'facebookUrl', website);
        //Updating Local Data
        dispatch(updateUserDataProperty({property: 'facebookUrl', value: website}));
        // alert("Your website has been updated");
    }
    const onChangeInstagramUrl = ()=>{
        const website = ensureProtocol(state.instagramUrl);
        //Updating database
        updateUserDatabaseKey(userData.uid, 'instagramUrl', website);
        //Updating Local Data
        dispatch(updateUserDataProperty({property: 'instagramUrl', value: website}));
        // alert("Your website has been updated");
    }

    const AccountDataEditor = (props: any)=>{
        return(
            <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                // flex: 1,
                width: '45%',
                maxWidth: 500,
                alignSelf: 'center',
                alignItems: 'flex-start',
                justifyContent: 'center',
                // borderColor: 'red',
                // borderWidth: 1,
            }}>
                <View style={{
                        width: '100%',
                        // borderColor: 'black', borderWidth: 3
                    }}
                >
                    {/* <Text style={{fontSize: 15, fontWeight: '500'}}>
                        {state.accountName}
                    </Text> */}
                    <Input
                        label={"Display Name"}
                        defaultValue={state.accountName}
                        onInputChange={(value:string)=>setState((prevState)=>{prevState.accountName = value; return prevState})}
                        style={{marginBottom: 10}}
                        inputStyle={{borderColor: COLORS.primaryBlue, borderRadius: 10, borderWidth: 1,}}
                    />
                    <CustomButton
                        onPress={onChangeAccountName}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: 12.5,
                        }}
                        style={{
                            backgroundColor: COLORS.primaryRed,
                            alignSelf:'flex-end',
                            // height: '25%',
                            padding:10,
                            width: '25%',
                            minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
                </View>
                <View style={{
                        width: '100%',
                        // borderColor: 'green', borderWidth: 1
                    }}
                >
                    {/* <Text style={{fontSize: 15, fontWeight: '500'}}>
                        {state.address}
                    </Text> */}
                    <ValidateInput
                        // style={{borderWidth: 1, borderColor: 'red', width: '100%'}}
                        inputStyle={{
                            borderWidth: 1,
                            borderColor: COLORS.primaryBlue,
                            borderRadius: 10,
                        }}
                        style={{marginBottom: 10}}
                        label={"Address"}
                        defaultValue={state.address}
                        onInputChange={(value:string)=>setState((prevState)=>{prevState.address = value; return prevState})}
                    />
                    <CustomButton
                        onPress={onChangeAddress}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: 12.5,
                        }}
                        style={{
                            backgroundColor: COLORS.primaryRed,
                            alignSelf:'flex-end',
                            // height: '25%',
                            padding:10,
                            width: '25%',
                            minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
                </View>
                {/* Phone Number */}
                <View style={{
                        width: '100%',
                    }}
                >
                    <ValidateInput
                        inputStyle={{
                            borderWidth: 1,
                            borderColor: COLORS.primaryBlue,
                            borderRadius: 10,
                        }}
                        style={{marginBottom: 10}}
                        name={'Phone Number'}
                        errorMessage={errorMessages.phoneNumberError}
                        errorName={'phoneNumberErrorFlag'}
                        onInputChange={(value: string)=>setState((prevState: any)=>{prevState.phoneNumber = value; return prevState;})}
                        regex={regexPatterns.phoneNumber}
                        defaultValue={state.phoneNumber}
                        label={"Phone Number"}
                    />
                    <CustomButton
                        onPress={onChangePhoneNumber}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: 12.5,
                        }}
                        style={{
                            backgroundColor: COLORS.primaryRed,
                            alignSelf:'flex-end',
                            // height: '25%',
                            padding:10,
                            width: '25%',
                            minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
                </View>
                {/* Email */}
                <View style={{
                        width: '100%',
                    }}
                >
                    <ValidateInput
                        inputStyle={{
                            borderWidth: 1,
                            borderColor: COLORS.primaryBlue,
                            borderRadius: 10,
                        }}
                        style={{marginBottom: 10}}
                        name={'Email'}
                        errorMessage={errorMessages.emailError}
                        errorName={'emailErrorFlag'}
                        onInputChange={(value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;})}
                        regex={regexPatterns.email}
                        defaultValue={state.email}
                        label={"Email"}
                    />
                    <CustomButton
                        onPress={onChangeEmail}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: 12.5,
                        }}
                        style={{
                            backgroundColor: COLORS.primaryRed,
                            alignSelf:'flex-end',
                            // height: '25%',
                            padding:10,
                            width: '25%',
                            minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
                </View>
                {/* Website */}
                <View style={{
                        width: '100%',
                    }}
                >
                    <ValidateInput
                        inputStyle={{
                            borderWidth: 1,
                            borderColor: COLORS.primaryBlue,
                            borderRadius: 10,
                        }}
                        style={{marginBottom: 10}}
                        name={'Website'}
                        errorMessage={errorMessages.websiteError}
                        errorName={'websiteErrorFlag'}
                        onInputChange={(value: string)=>setState((prevState: any)=>{prevState.website = value; return prevState;})}
                        regex={regexPatterns.url}
                        defaultValue={state.website}
                        label={"Website"}
                    />
                    <CustomButton
                        onPress={onChangeWebsite}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: 12.5,
                        }}
                        style={{
                            backgroundColor: COLORS.primaryRed,
                            alignSelf:'flex-end',
                            // height: '25%',
                            padding:10,
                            width: '25%',
                            minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
                </View>
                {/* Facebook Url */}
                <View style={{
                        width: '100%',
                    }}
                >
                    <ValidateInput
                        inputStyle={{
                            borderWidth: 1,
                            borderColor: COLORS.primaryBlue,
                            borderRadius: 10,
                        }}
                        style={{marginBottom: 10}}
                        name={'Facebook Url'}
                        errorMessage={errorMessages.websiteError}
                        errorName={'websiteErrorFlag'}
                        onInputChange={(value: string)=>setState((prevState: any)=>{prevState.facebookUrl = value; return prevState;})}
                        regex={regexPatterns.url}
                        defaultValue={state.facebookUrl}
                        label={"Facebook Url"}
                    />
                    <CustomButton
                        onPress={onChangeFacebookUrl}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: 12.5,
                        }}
                        style={{
                            backgroundColor: COLORS.primaryRed,
                            alignSelf:'flex-end',
                            // height: '25%',
                            padding:10,
                            width: '25%',
                            minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
                </View>
                {/* Instagram Url */}
                <View style={{
                        width: '100%',
                    }}
                >
                    <ValidateInput
                        inputStyle={{
                            borderWidth: 1,
                            borderColor: COLORS.primaryBlue,
                            borderRadius: 10,
                        }}
                        style={{marginBottom: 10}}
                        name={'Instagram Url'}
                        errorMessage={errorMessages.websiteError}
                        errorName={'websiteErrorFlag'}
                        onInputChange={(value: string)=>setState((prevState: any)=>{prevState.instagramUrl = value; return prevState;})}
                        regex={regexPatterns.url}
                        defaultValue={state.instagramUrl}
                        label={"Instagram Url"}
                    />
                    <CustomButton
                        onPress={onChangeInstagramUrl}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: 12.5,
                        }}
                        style={{
                            backgroundColor: COLORS.primaryRed,
                            alignSelf:'flex-end',
                            // height: '25%',
                            padding:10,
                            width: '25%',
                            minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
                </View>
            </ScrollView>
        )
    }

    const DealsEditor = (props: any)=>{

        const onUpdateDeals = ()=>{
            if(dealOne.link && !isUrlValid(dealOne.link)) return alert("Your link for deal one is not valid! Please check the formatting")
            if(dealTwo.link && !isUrlValid(dealTwo.link)) return alert("Your link for deal two is not valid! Please check the formatting")

            // console.log("Deal one: ", dealOne);
            // console.log("Deal two: ", dealTwo);

            //Updating database
            updateUserDatabaseKey(userData.uid, 'dealOne', dealOne);
            updateUserDatabaseKey(userData.uid, 'dealTwo', dealTwo);
            //Updating Local Data
            dispatch(updateUserDataProperty({property: 'dealOne', value: dealOne}));
            dispatch(updateUserDataProperty({property: 'dealTwo', value: dealTwo}));

            setShowModal(false);

            alert("Your deals have been updated");
        }

        // const [test, setTest] = useState({...userData.dealOne} || {title: "test 1", details: "test 2", link: "test 3"})

        const renderDealInputs = ()=>{
            let dealsInput = [];
            // console.log("deals: ", deals);
            for (let index = 0; index < 2; index+=1){
                const dealObj = ((index: number)=>{
                    if(index) return dealTwo
                    else return dealOne
                })(index)
                const dealStateFunction = ((index: number)=>{
                    if(index) return setDealTwo
                    else return setDealOne
                })(index)
                dealsInput.push(
                    <View key={index} style={{flex: 95/2, justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{
                            // marginLeft: '8%',
                            fontWeight: '500', fontSize: 14, alignSelf: 'center'
                        }}>
                            Deal {index+1}
                        </Text>
                        <Input
                            label={`Title`}
                            style={{width: '50%', minWidth: 350, }}
                            inputStyle={{
                                width: '50%',
                                minWidth: 350,
                                borderColor: COLORS.primaryBlue,
                                borderRadius: 10,
                                borderWidth: 1,
                            }}
                            defaultValue={dealObj.title || ""}
                            onInputChange={(value:string)=>dealStateFunction((prevState: any)=>{prevState.title = value; return prevState})}
                        />
                        <Input
                            label={`Details`}
                            style={{width: '50%', minWidth: 350}}
                            inputStyle={{
                                width: '50%',
                                minWidth: 350,
                                borderColor: COLORS.primaryBlue,
                                borderRadius: 10,
                                borderWidth: 1,
                            }}
                            defaultValue={dealObj.details || ""}
                            onInputChange={(value:string)=>dealStateFunction((prevState: any)=>{prevState.details = value; return prevState})}
                        />
                        <ValidateInput
                            errorMessage={"There is a problem with your link formatting!"}
                            errorName={"Link Error"}
                            regex={regexPatterns.url}
                            label={`Link`}
                            style={{width: '50%', minWidth: 350}}
                            inputStyle={{
                                width: '50%',
                                minWidth: 350,
                                borderColor: COLORS.primaryBlue,
                                borderRadius: 10,
                                borderWidth: 1,
                            }}
                            defaultValue={dealObj.link || ""}
                            onInputChange={(value:string)=>dealStateFunction((prevState: any)=>{prevState.link = value; return prevState})}

                            // regex={regexPatterns.username}
                            // errorMessage={'Usernames can only contain up to 30 of these characters: a-z, 0-9, _ and . (only in the middle)'}
                            // errorName={'usernameError'}
                            // defaultValue={state.accountName}
                            // onInputChange={(value:string)=>setState((prevState)=>{prevState.accountName = value; return prevState})}
                            // style={{marginBottom: 10}}
                            // inputStyle={{borderColor: COLORS.primaryBlue, borderRadius: 10, borderWidth: 1,}}
                            // state={state}
                            // setState={setState}
                        />
                    </View>
                )
            }
            return dealsInput
        }

        return(
            <View style={{ alignItems: 'center', justifyContent: 'center'}}>
                <View style={{width: '80%', alignSelf: 'center', flexDirection: 'row', justifyContent: 'space-around'}}>
                    {renderDealInputs()}
                </View>
                <CustomButton
                    onPress={onUpdateDeals}
                    title={"Submit"}
                    textStyle={{
                        color: 'white',
                        fontWeight: '500', 
                        fontSize: 12.5,
                    }}
                    style={{
                        backgroundColor: COLORS.primaryRed,
                        alignSelf:'center',
                        marginTop: 25,
                        // height: '25%',
                        padding:10,
                        width: 100,
                        // minWidth: 100,
                        borderRadius: 10,
                    }}
                />
            </View>
        )
    }

    const SpecialsEditor = (props: any)=>{
        const { specialType } = props;

        const specials: string[] = (()=>{
            if(specialType === 'daily' && userData.dailySpecials) return Object.values(userData.dailySpecials)
            if(specialType === 'weekly' && userData.weeklySpecials) return Object.values(userData.weeklySpecials)
            if(specialType === 'monthly' && userData.monthlySpecials) return Object.values(userData.monthlySpecials)
            return [];
        })()

        const stateFunction = (()=>{
            if(specialType === 'daily') return setDailySpecials
            if(specialType === 'weekly') return setWeeklySpecials
            if(specialType === 'monthly') return setMonthlySpecials
            return ()=>{}
        })()

          
        const onModifySpecial = ()=>{
            const specialState = (()=>{
                if(specialType === 'daily') return dailySpecials
                if(specialType === 'weekly') return weeklySpecials
                if(specialType === 'monthly') return monthlySpecials
            })()

            // console.log("Daily Specials: ", dailySpecials)
            // console.log("Weekly Specials: ", weeklySpecials)
            // console.log("Monthly Specials: ", monthlySpecials)

            //Updating database
            updateUserDatabaseKey(userData.uid, `${specialType}Specials`, specialState);
            //Updating local data
            dispatch(updateUserDataProperty({property: `${specialType}Specials`, value: specialState}))
            alert("Your specials have been updated.");
        }

        const renderSpecials = ()=>{
            // if(!specials.length) return 
            let specialsInputs = [];
            for (let index = 0; index < 3; index+=1){
                specialsInputs.push(
                    <Input
                        key={index}
                        label={`Special ${index+1}`}
                        style={{width: '50%', minWidth: 350}}
                        inputStyle={{
                            width: '50%',
                            minWidth: 350,
                            borderColor: COLORS.primaryBlue,
                            borderRadius: 10,
                            borderWidth: 1,
                        }}
                        defaultValue={specials[index] ? specials[index] : ""}
                        onInputChange={(value:string)=>stateFunction((prevState: any)=>{prevState[index] = value; return prevState})}
                    />
                )
            }
            return specialsInputs
        }
        return(
            <View style={{
                alignItems: 'center',
                justifyContent: specialType === 'monthly' ? 'space-around' : 'center',
                flexDirection: specialType === 'monthly' ? 'row' : 'column'
            }}>
                <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    {renderSpecials()}
                    <CustomButton
                        onPress={onModifySpecial}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: 12.5,
                        }}
                        style={{
                            backgroundColor: COLORS.primaryRed,
                            alignSelf:'center',
                            // height: '25%',
                            padding:10,
                            width: 100,
                            // minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
                </View>
                {specialType === 'monthly' && <Card style={{flex: 1, padding: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <TouchableContainer onPress={()=>{
                        setShowModal(false);
                        setShowUploadPictureModal(true);
                    }} style={{flexGrow: 1}}>
                        <Image 
                            source={monthlySpecialsImageKey ? {uri: `${S3_URL}/${monthlySpecialsImageKey}`}: require('../assets/alwayslocal-logo.png')} 
                            style={{
                                flexGrow: 1,
                                // borderWidth: 2,
                                // borderColor: 'red',
                                height: 300,
                                width: 300,
                            }} 
                            resizeMode="contain" 
                        />
                        <Text style={{textAlign: 'center', fontSize: 14, fontWeight: '500'}}>
                            Click here to change your monthly special image
                        </Text>
                    </TouchableContainer>
                    {/* <TouchableContainer>
                        <Text> Image here </Text>
                    </TouchableContainer> */}
                </Card>}
            </View>
        )
    }

    const renderDeals = ()=>{
        const deals = [dealOne, dealTwo]
        if(!dealOne && !dealTwo) return(
            <View>
                <Text style={{fontSize: 12, color: 'white', fontWeight: '800'}}> Click here to create your deals </Text>
            </View>
        )
        return Object.values(deals).filter((deal: any)=>{
            if(!deal.title|| !deal.details || !deal.link) return false;
            return true;
        }).map((deal: any, index: number)=>{
            const { title, details, link} = deal
            return (
                <View key={index} style={{flex: .95/2, width: '35%'}}>
                    <Card style={{flex: 1, padding: 10, marginBottom: 10}}>
                        {/* <View> */}
                            {/* Deal title */}
                            <View style={{alignItems: 'center'}}>
                                <Text style={{ fontSize:15, textAlign: 'center', textAlignVertical: 'center', fontWeight: '500'}}> {`${title}`} </Text>
                            </View>
                            {/* Deal details */}
                            <View style={{ alignItems: 'center'}}>
                                <Text style={{textAlign: 'center', textAlignVertical: 'center'}}> {`${details}`} </Text> 
                            </View>
                        {/* </View> */}
                        {/* Who posted it? Label */}
                    </Card>
                    <View style={{alignSelf: 'flex-end', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        {/* <Text style={{textAlign: 'center'}}> From: <Text> Park Tavern</Text> </Text>  */}
                        <CustomButton
                            onPress={(event: any)=>{
                                event.preventDefault();
                                console.log("Link: ", link);
                                Linking.openURL(link);
                            }}
                            title={
                                <Text style={{
                                    fontSize: 16,
                                    fontWeight: '500',
                                    textAlignVertical: 'center'
                                }}>
                                    <FontAwesome5 name="money-bill-alt" size={18} color="white" /> REDEEM!
                                </Text>
                            }
                            style={{
                                backgroundColor: COLORS.primaryRed,
                                padding: 10,
                                borderRadius: 10,
                                width: '75%',
                                alignSelf: 'center',
                                // margin: 5,
                            }}
                            textStyle={{
                                color: 'white'
                            }}
                        />
                    </View>
                </View>
            )
        })
    }

    const renderModalContent = ()=>{
        if(edittingMode === "coverPictures") return <AccountChoosePictures/>
        if(edittingMode === "selectProfilePicture") return <AccountSelectProfilePicture profilePictureKey={profilePictureKey} onPictureSelected={onPictureSelected}/>
        if(edittingMode === "nameAndAddressChange") return <AccountDataEditor/>
        if(edittingMode === "dailySpecialsChange") return <SpecialsEditor specialType="daily"/>
        if(edittingMode === "weeklySpecialsChange") return <SpecialsEditor specialType="weekly"/>
        if(edittingMode === "monthlySpecialsChange") return <SpecialsEditor specialType="monthly"/>
        if(edittingMode === "dealsChange") return <DealsEditor/>
        if(edittingMode === "entertainmentChange") return <AccountChoosePictures mode={"entertainment"}/>
    }

    const openMapsApp = (latitude: number, longitude: number, label = '') => {
        const isWeb = Platform.OS === 'web';
        const scheme = isWeb
          ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
          : Platform.select({
              ios: `maps:0,0?q=${label}@${latitude},${longitude}`,
              android: `geo:${latitude},${longitude}?q=${label}`,
            });
        //@ts-ignore
        Linking.openURL(scheme);
    };

    const onPictureSelected = ()=>{
        setShowModal(false);
    }
    
    const onAccountNameAndAddressChange = ()=>{
        setEdittingMode('nameAndAddressChange');
        setShowModal(true);
    }

    const onProfilePicturePress = ()=>{
        if(!uploadedPictures.length) return alert("Click your cover photo above before selecting a profile picture!");
        setEdittingMode('selectProfilePicture');
        setShowModal(true);
    }

    const onRemoveProfilePicture = async ()=>{

        let response = await confirmDecision(
            `Removing profile picture`,
            `Are you sure you would like to remove your profile picture?`
        );
            
        if(!response) return;
        // updateLocalPhotoBasedOnModalType(modalType, '');

        // Delete old photo from cache
        //@ts-ignore
        // deleteImageFromCache(userData[`${keyProperty}`]);

        // deletePhotoFromStorageIfNotUsedInDating();
        //@ts-ignore
        let pictureKey = userData[`${keyProperty}`];
        // deleteFromStorage(pictureKey, "image")

        //update database key
        // updateDatabaseKeyBasedOnModalType(modalType, '');
        //Update globalState
        // updateGlobalStateImageKey('');

        // setShowUploadPictureModal(false);
    }

    const renderMapButton = ()=>{
        if(isObjEmpty(state.coords)){
            return(
                <View style={{flex: 1, alignContent: 'center',justifyContent: 'center'}}>
                    <Text style={{fontSize: 12, fontWeight: '800'}}> No address data here yet! </Text>
                </View>
            )
        }
        //@ts-ignore TODO - fix this;
        const {latitude,longitude} = state.coords;
        return(
            <TouchableContainer onPress={()=>openMapsApp(latitude, longitude)} style={{flex: 1, zIndex: 1}}>
                <MapView
                    scrollEnabled={false}
                    // clusterColor={COLORS.primaryRed}
                    // tracksViewChanges={false}
                    //@ts-ignore
                    googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                    provider="google"
                    style={{height: '100%', width: '100%', zIndex:0, borderRadius: 10}}
                    initialRegion={{
                        latitude: latitude,
                        longitude: longitude,
                        latitudeDelta: 100, // smaller number => more zoom
                        longitudeDelta: 100, // smaller number => more zoom
                    }}
                >
                    <Marker
                        coordinate={{
                            latitude: latitude,
                            longitude: longitude,
                        }}
                    />
                </MapView>
            </TouchableContainer>
        )
    }

    const isThereAValidSpecial = (obj: Object)=>{
        if(!obj) return false;
        const arr = Object.values(obj);
        return arr.some((val: string)=>{
            if(val) return true;
            else return false;
        })
    }

    const onPictureUploaded = (imageKey: string)=>{
        //updateFrontEnd
        // updateLocalPhotoBasedOnModalType(modalType, imageKey);
        dispatch(updateUserDataProperty({property: 'monthlySpecialsImageKey', value: imageKey}))

        //update database key
        updateUserDatabaseKey(userData.uid, 'monthlySpecialsImageKey', imageKey);

        //Update globalState
        setMonthlySpecialsImageKey(imageKey);

        // setShowUploadPictureModal(false);
    }

    const onRemoveMonthlySpecialImage = async ()=>{
        // let imageTypeName = (modalType === 'cover-photo' ? 'cover photo' : 'profile picture');
        // let keyProperty = (modalType === 'cover-photo' ? 'coverPhotoKey' : 'profilePictureKey');

        let response = await confirmDecision(
            `Removing monthly specials image`,
            `Are you sure you would like to proceed?`
        );
            
        if(!response) return;
        //Update local global state
        dispatch(updateUserDataProperty({property: 'monthlySpecialsImageKey', value: ""}))
        //update database key
        updateUserDatabaseKey(userData.uid, 'monthlySpecialsImageKey', "");
        // Delete old photo from cache
        //@ts-ignore
        deleteImageFromCache(monthlySpecialsImageKey);
        //Update monthlySpecialsImageKey
        setMonthlySpecialsImageKey("")

        // setShowUploadPictureModal(false);
    }

    const [verifyingRecaptcha, setVerifyingRecaptcha] = useState(false);
    if(verifyingRecaptcha){
        return(
            <FirebaseRecaptchaVerifierModal
                style={{width: 200, height: 200, borderWidth: 1, borderColor: 'black'}}
                ref={recaptchaVerifier}
                firebaseConfig={firebase.app().options}
            /> 
        )
    }

    const NextButton = ({ onPress }: any) => (
        <TouchableContainer onPress={onPress}>
            <AntDesign name="rightcircleo" size={24} color="white" />
        </TouchableContainer>
    );
    
    const PrevButton = ({ onPress }: any) => (
        <TouchableContainer onPress={onPress}>
            <AntDesign name="leftcircleo" size={24} color="white" />
        </TouchableContainer>
    );

    return(
        <View style={{
            flex: 1,
            //TODO: Remove this, but it simulates a fixed size for web.
            height: 1500,
            width: 500,
            backgroundColor: COLORS.primaryBgColor,
        }}>
            <VerifyPhoneNumberModal
                phoneNumber={state.phoneNumber}
                setShowModal={setShowVerifyPhoneNumberModal}
                showModal={showVerifyPhoneNumberModal}
                onCloseModal={()=>{}}
                onVerifyPhoneNumberSuccess={onVerifyPhoneNumberSuccess}
            />
            <ReauthCurrentUserModal
                setShowModal={setShowReauthModal}
                onReauthSuccess={onReauthSuccess}
                showModal={showReauthModal}
                onCloseModal={()=>{}}
            />
            <UploadPictureModal
                showModal={showUploadPictureModal}
                setShowModal={setShowUploadPictureModal}
                onCloseModal={()=>setShowModal(true)}
                onPictureUploaded={onPictureUploaded}
                picturePrefix={`${userData.uid}-monthly-special-image`}
                selectedImageKey={monthlySpecialsImageKey}
                onRemoveSelectedImage={onRemoveMonthlySpecialImage}
            />
            <CustomModal
                onBack={()=>{
                    // setPostContentInput("");
                    setShowModal(false);
                    // setShowCommentsModal(true)
                }}
                title={getTitle()}
                visibilityFlag={showModal}
                visibilityControlFunc={setShowModal}
                showSubmit={false}
                scroll={false}
                containerStyle={{
                    alignSelf: 'center',
                    justifyContent: 'center',
                    height: '80%',
                    width: '80%',
                    // borderWidth: 1,
                    // borderColor: 'red',
                }}
                cardStyle={{
                    // height: modalSize,
                    width: SCREEN_SIZE.width,
                    // position: (Platform.OS === 'ios' ? 'absolute' : 'relative'),
                    // top: (Platform.OS === 'ios' ? Header : 0),
                }}
            >  
                {renderModalContent()}
            </CustomModal>
            {/* Slideshow with profile pictures */}
            <View style={{
                height: '35%', 
                // borderWidth: 2, borderColor: 'red'
            }}>
                {/*//@ts-ignore */}
                <Swiper
                    controlsEnabled={true}
                    controlsProps={{
                        prevTitle: "",
                        nextTitle: "",
                        NextComponent: NextButton,
                        PrevComponent: PrevButton,
                    }}
                    key={uploadedPictures.length}containerStyle={{flex: 1}}
                >
                    {
                    uploadedPictures.length ?
                        uploadedPictures.map((item, index) => (
                            <TouchableContainer key={index} onPress={onModifyPictures} style={{flexGrow: 1}}>
                                <Image 
                                    source={{ uri: item }} 
                                    style={{
                                    flexGrow: 1,
                                    // borderWidth: 2,
                                    // borderColor: 'red',
                                    // height: 50,
                                    // width: scrollViewWidth
                                    }} 
                                    resizeMode="cover" 
                                />
                            </TouchableContainer>
                        ))
                    :
                        <TouchableContainer onPress={onModifyPictures} style={{flexGrow: 1}}>
                            <Image 
                                source={require('../assets/alwayslocal-logo.png')} 
                                style={{
                                flexGrow: 1,
                                // borderWidth: 2,
                                // borderColor: 'red',
                                // height: 50,
                                // width: scrollViewWidth
                                }} 
                                resizeMode="contain" 
                            />
                        </TouchableContainer>

                    }
                </Swiper>
            </View>
            <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                    flex: 1,
                    backgroundColor: COLORS.primaryBgColor,
                    // padding: 2.5
            }}>
                <View style={{
                    flexDirection: 'row', justifyContent:'space-between',
                    padding: 2.5,
                    // borderColor: 'red',
                    // borderWidth: 1,
                }}>
                    <Card style={{
                        flex: 1.95/3, flexDirection: 'row',
                        // padding: 2.5
                        // borderColor: 'pink', borderWidth: 2,
                    }}>
                            <TouchableContainer
                                style={{
                                    flexGrow: 1,
                                    height: '100%',
                                    width: '50%',
                                    alignItems: 'flex-start',
                                    // borderColor: 'red',
                                    // borderWidth: 1,
                                }}
                                onPress={onProfilePicturePress}>
                                <Image 
                                    source={{ uri: profilePictureKey ? `${S3_URL}/${profilePictureKey}` : require('../assets/placeholder-picture.png')}} 
                                    style={{
                                        flexGrow: 1,
                                        height: '100%',
                                        width: '100%',
                                        // borderWidth: 1,
                                        // borderColor: 'blue',
                                        alignSelf: 'flex-start',
                                        // flex: 1,
                                        // aspectRatio: 1.5/2,
                                        borderTopLeftRadius: 10,
                                        borderBottomLeftRadius: 10,
                                    }} 
                                    resizeMode="cover" 
                                />
                            </TouchableContainer>

                        <TouchableContainer
                            onPress={onAccountNameAndAddressChange}
                            style={{width: '50%', padding: 10, alignItems: 'flex-start', justifyContent: 'flex-start', minHeight: 125}}
                        >
                            <Text style={{marginBottom: 5, fontSize: 14, fontWeight: '800', width: '100%'}}>
                                {state.accountName}
                            </Text>
                            <Text style={{marginVertical: 2, fontSize: 12,width: '100%'}}>
                                {state.address}
                            </Text>
                            {!!state.phoneNumber &&
                                <TouchableContainer onPress={()=>Linking.openURL(`tel:${state.phoneNumber}`)}>
                                    <Text style={{marginVertical: 2, fontSize: 12,width: '100%', color: 'blue',}}>
                                        {formatPhoneNumber(state.phoneNumber)}
                                    </Text>
                                </TouchableContainer>
                            }
                            <View style={{
                                marginTop: 5,
                                width: '100%',
                                alignSelf: 'center',
                                // alignItems: 'baseline',
                                // flexDirection: 'column',
                                alignItems: !state.website && !state.facebookUrl && !state.instagramUrl ? 'baseline' : 'center',
                                flexDirection: !state.website && !state.facebookUrl && !state.instagramUrl ? 'column' : 'row',
                                justifyContent: 'space-around'
                            }}>
                                <TouchableContainer onPress={()=>Linking.openURL(`mailto:${state.email}`)}>
                                    <MaterialIcons name="email" size={24} color="black" />
                                </TouchableContainer>
                                {!!state.website && <TouchableContainer onPress={()=>{Linking.openURL(state.website)}}>
                                    <MaterialCommunityIcons 
                                    name="web" size={24} color="black" />
                                </TouchableContainer>}
                                {!!state.facebookUrl && <TouchableContainer onPress={()=>{Linking.openURL(state.facebookUrl)}}>
                                    <FontAwesome5 name="facebook" size={24} color="black" />
                                </TouchableContainer>}
                                {!!state.instagramUrl && <TouchableContainer onPress={()=>{Linking.openURL(state.instagramUrl)}}>
                                    <AntDesign name="instagram" size={24} color="black" />
                                </TouchableContainer>}
                            </View>
                        </TouchableContainer>
                    </Card>
                    <Card style={{
                        flex: 1/3,
                        // padding: 5,
                        // backgroundColor: 'red'
                    }}>
                        {renderMapButton()}
                    </Card>
                </View>
                {/* Daily and Monthly Specials */}
                <View style={{
                    flexDirection: 'row', justifyContent: 'space-around',
                    padding: 2.5,
                    // padding: 1,
                    // borderColor: 'green',
                    // borderWidth: 1,
                }}>
                    <TouchableContainer style={{flex: .97/2}} onPress={()=>{setShowModal(true); setEdittingMode("dailySpecialsChange")}}>
                        <Card style={{flex: 1, padding: 10}}>
                            <Text style={{alignSelf: 'center', fontSize: 15, fontWeight: '500'}}>Daily <Text style={{fontWeight: '800', color: COLORS.primaryRed}}> Specials </Text></Text>
                            {isThereAValidSpecial(userData.dailySpecials) ?
                                <View>
                                    { userData.dailySpecials[0] && <Text>{userData.dailySpecials[0]}</Text> }
                                    { userData.dailySpecials[1] && <Text>{userData.dailySpecials[1]}</Text> }
                                    { userData.dailySpecials[2] && <Text>{userData.dailySpecials[2]}</Text> }
                                </View>
                                :
                                <Text style={{fontSize: 12, fontWeight: '800'}}> Click to create your daily specials </Text>
                            }
                        </Card>
                    </TouchableContainer>

                    <TouchableContainer style={{flex: .97/2}} onPress={()=>{setShowModal(true); setEdittingMode("weeklySpecialsChange")}}>
                        <Card style={{flex: 1, padding: 10}}>
                            <Text style={{alignSelf: 'center', fontSize: 15, fontWeight: '500'}}> Weekly <Text style={{fontWeight: '800', color: COLORS.primaryRed}}> Specials </Text></Text>
                                {isThereAValidSpecial(userData.weeklySpecials) ?
                                    <View>
                                        { userData.weeklySpecials[0] && <Text>{userData.weeklySpecials[0]}</Text> }
                                        { userData.weeklySpecials[1] && <Text>{userData.weeklySpecials[1]}</Text> }
                                        { userData.weeklySpecials[2] && <Text>{userData.weeklySpecials[2]}</Text> }
                                    </View>
                                    :
                                    <Text style={{fontSize: 12, fontWeight: '800'}}> Click to create your weekly specials </Text>
                                }
                        </Card>
                    </TouchableContainer>

                </View>
                {/* Monthly Specials */}
                <View style={{padding: 2.5}}>
                    <TouchableContainer style={{flex: 1}} onPress={()=>{setShowModal(true); setEdittingMode("monthlySpecialsChange")}}>
                        <Card style={{flex: 1, flexDirection: 'row', padding: 10}}>
                            <View style={{flex: 1.95/3,}}>
                                <Text style={{alignSelf: 'center', fontSize: 15, fontWeight: '500'}}> Monthly <Text style={{fontWeight: '800', color: COLORS.primaryRed}}> Specials </Text></Text>
                                {
                                    isThereAValidSpecial(userData.monthlySpecials)
                                    ?
                                    <View>
                                        { userData.monthlySpecials[0] && <Text>{userData.monthlySpecials[0]}</Text> }
                                        { userData.monthlySpecials[1] && <Text>{userData.monthlySpecials[1]}</Text> }
                                        { userData.monthlySpecials[2] && <Text>{userData.monthlySpecials[2]}</Text> }
                                    </View>
                                    :
                                    <Text style={{ fontSize: 12, fontWeight: "800"}}> Click to create your monthly specials </Text>
                                }
                            </View>
                            <Image 
                                source={monthlySpecialsImageKey ? {uri: `${S3_URL}/${monthlySpecialsImageKey}`} : require('../assets/alwayslocal-logo.png')} 
                                style={{
                                    flexGrow: 1/3,
                                    // height: '100%',
                                    // width: '50%',
                                    minHeight: 100,
                                    borderTopRightRadius: 10,
                                    borderBottomRightRadius: 10,
                                }} 
                                resizeMode="contain" 
                            />
                        </Card>
                    </TouchableContainer>
                </View>

                <TouchableContainer onPress={()=>{setShowModal(true); setEdittingMode("dealsChange")}}>
                    <Card style={{padding: 10, backgroundColor: COLORS.slateGray}}>
                        <Text style={{fontSize: 20, color: "white"}}>
                            <MaterialCommunityIcons name="pig-variant" size={24} color="white" /> Deals
                        </Text>
                        <View style={{flexDirection: 'row', justifyContent: 'space-around', padding: 2.5}}>
                            {renderDeals()}
                        </View>
                    </Card>
                </TouchableContainer>

                <View style={{padding: 10}}>
                    <Text style={{fontSize: 20, color: "black", textAlignVertical: 'center'}}>
                        <SimpleLineIcons name="people" size={24} color="black" /> Staff
                    </Text>
                    <ScrollView
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        contentContainerStyle={{flex: 1, flexDirection: 'row', justifyContent: 'space-around', padding: 2.5}}>
                        <Card style={{flex: .97/2, padding:2.5}}>
                            <Image 
                                source={require('../assets/alwayslocal-logo.png')} 
                                style={{
                                    // flex: 1,
                                    // borderWidth: 2,
                                    // borderColor: 'blue',
                                    zIndex: 0,
                                    height: 150,
                                    width: '100%',
                                }} 
                                resizeMode="contain" 
                            />
                            <Entypo 
                                style={{
                                    zIndex: 1, 
                                    position: 'absolute', 
                                    top: 10, 
                                    left: 10, 
                                }} 
                                name="info-with-circle" 
                                size={24} 
                                color="black" 
                            />
                        </Card>
                        <Card style={{flex:.97/2, alignItems: 'center', justifyContent: 'center', padding:2.5 }}>
                            <Text style={{ textAlign: 'center', fontWeight: '500' }}>
                                This will be populated with your staff once they join!
                            </Text>
                        </Card>
                    </ScrollView>
                </View>

                <View style={{padding: 10, height: 200, backgroundColor: COLORS.slateGray}}>
                    <Text style={{fontSize: 20, color: "white", textAlignVertical: 'center'}}>
                        <SimpleLineIcons name="people" size={24} color="white" /> Entertainment
                    </Text>
                    <Card style={{flex:1, alignItems: 'center', justifyContent: 'center', padding:5 }}>
                            <Text style={{ textAlign: 'center', fontWeight: '500' }}>
                                This will be populated with your enertainment once they join!
                            </Text>
                            <Text style={{ fontSize: 10, textAlign: 'center'}}>
                                Here are some examples
                            </Text>
                            {(()=>{
                                const examples = [
                                    {time:"04-27 -10PM -AM", name: "Spank The Band" },
                                    {time:"04/28 - 5PM - 9PM", name: "Prize Trivia!"},
                                    {time:"04/29 - 7PM - 1AM", name: "Karaoke Prize Night"},
                                    {time:"04/30 - 10PM - 2AM", name: "Kevin's Band"},
                                    {time:"05/01 - 5PM - 9PM", name: "Prize Trivia!"},
                                ]
                                return examples.map((ent)=>{
                                    return(
                                        <View key={ent.time} style={{
                                            width: '90%',
                                            flexDirection: 'row',
                                            alignContent: 'flex-start',
                                            justifyContent: 'space-between',
                                        }}>
                                            <Text style={{fontSize: 14}}>{ent.time}</Text>
                                            <TouchableContainer onPress={()=>alert(`This will take you to the band page "${ent.name}"!`)}>
                                                <Text style={{fontSize: 14, textDecorationLine: 'underline'}}>{ent.name}</Text>
                                            </TouchableContainer>
                                        </View>
                                    )
                                })
                            })()}
                    </Card>
                    {/* <Swiper key={uploadedEntPictures.length} containerStyle={{flex: 1}}>
                    {
                    uploadedEntPictures.length ?
                    uploadedEntPictures.map((item, index)=>{
                        return(
                            <TouchableContainer key={index} onPress={onModifyEntertainmentPictures} style={{flexGrow: 1}}>
                                <Image 
                                    source={{ uri: item }} 
                                    style={{
                                    flexGrow: 1,
                                    // borderWidth: 2,
                                    // borderColor: 'red',
                                    // height: 50,
                                    // width: scrollViewWidth
                                    }} 
                                    resizeMode="cover" 
                                />
                            </TouchableContainer>
                        )
                    })
                    :
                        <TouchableContainer onPress={onModifyEntertainmentPictures} style={{flexGrow: 1}}>
                            <Image 
                                source={require('../assets/alwayslocal-logo.png')} 
                                style={{
                                flexGrow: 1,
                                // borderWidth: 2,
                                // borderColor: 'red',
                                // height: 50,
                                // width: scrollViewWidth
                                }} 
                                resizeMode="contain" 
                            />
                        </TouchableContainer>

                    }
                </Swiper> */}
                </View>


            </ScrollView>
        </View>
    )
}

export default AccountProfile;

