import { StyleSheet, Text, View, Image, useWindowDimensions, Linking } from 'react-native';
import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import { AntDesign } from '@expo/vector-icons'
import ValidateInput from '../components/ValidateInput';
import HomeHeader from '../navigation/components/HomeHeader'
import { COLORS, regexPatterns, SCREEN_SIZE } from '../Constants';
import { useState } from 'react';
import SignUpSheet from '../navigation/components/SignUpSheet';
import React from 'react';
import isMobile from '../helpers/isMobile';
import Fire from '../Fire';
import isAccountInformationTaken from '../helpers/IsAccountInformationTaken';
import TouchableContainer from '../components/TouchableContainer';
const firebase = Fire.getFirebase();

export default function App({navigation, route}: any) {
    const { isLoggedIn } = route.params;
    const window = useWindowDimensions();

    const [state, setState] = useState({
      username: "",
      usernameError: 'Usernames can only contain up to 30 of these characters: a-z, 0-9, _ and . (only in the middle)',
  
      firstName: "",
      firstNameError: 'First names must start with a capital letter and can only contain up to 30 characters',
  
      lastName: "",
      lastNameError: 'Last names must start with a capital letter and can only contain up to 30 characters',
  
      phoneNumber: "",
      phoneNumberError: 'Phone numbers must be of the form "+ (country code) (area code) (number)" ',
      countryCode: "+1",
  
      email: "",
      emailError: 'Email must be valid',
      
      password: "",
      passwordError: 'Password must be greater than 6 characters',
  
      error: "",
      
      firstNameFlag: false,
      lastNameFlag: false,
      usernameErrorFlag: false,
      nameFlag: false,
      emailErrorFlag: false,
      phoneNumberErrorFlag: false,
      passwordErrorFlag: false,
    });

    const signUpInputs = [{
          name: "First Name",
          regex: regexPatterns.username,
          errorMessage: state.firstName,
          errorName: "firstNameFlag",
          onInputChange: (value: string)=>setState((prevState: any)=>{return {...prevState, firstName: value}}),
          defaultValue: "",
        },
        {
          name: "Last Name",
          regex: regexPatterns.username,
          errorMessage: state.lastName,
          errorName: "lastNameFlag",
          onInputChange: (value: string)=>setState((prevState: any)=>{prevState.lastName = value; return prevState;}),
          defaultValue: "",
        },
        {
          name: "Email",
          regex: regexPatterns.email,
          errorMessage: state.emailError,
          errorName: "emailFlag",
          onInputChange: (value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;}),
          defaultValue: "",
          isRequired: true,
        },
        {
          name: "Phone Number",
          regex: regexPatterns.phoneNumber,
          errorMessage: state.phoneNumberError,
          errorName: "phoneNumber",
          onInputChange: (value: string)=>setState((prevState: any)=>{prevState.phoneNumber = value; return prevState;}),
          defaultValue: "",
        },
    ]

    const onSubmitForm = async ()=>{
      const {firstName, lastName, email, phoneNumber} = state;;
      if(!email) return alert("At least enter your email!");
      const response = await isAccountInformationTaken(phoneNumber, email, "");
      const {isPhoneNumberTaken, isEmailTaken} = JSON.parse(response);
      if(isEmailTaken) return alert("We have an account with your email already! \n Click 'forgot password' on the login page");
      if(isPhoneNumberTaken) return alert("We have an account with your number already! \n Click 'forgot password' on the login page");
      firebase.database().ref(`emailList/${email}`).set({
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: phoneNumber,
      })
      alert("We'll let you know as soon as we launch!");
      setState((prevState: any)=>{
        prevState.firstName = "";
        prevState.lastName = "";
        prevState.email = "";
        prevState.phoneNumber = "";
        return prevState;
      })
    }
  
    // return(
    //   <View style={{...styles.container}}>
    //   </View>
    // )
    // return (
    //   <View style={{
    //     // flex: 1,
    //     backgroundColor: '#F8FAFC',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     borderWidth: 5,
    //     borderColor: 'green',
    //     }}>
    //       <View style={{
    //           flex: 1,
    //           // height: '100%',
    //           // width: '100%',
    //           alignItems: 'center',
    //           justifyContent: 'center',
    //           flexDirection: window.width < 1100 ? 'column' : 'row',
    //           borderWidth: 5,
    //           borderColor: 'black',
    //       }}>
    //         <View style={{
    //             ...styles.signUpForm,
    //             // flex: 1,
    //             height: 300,
    //             width: 100,
    //             backgroundColor: 'green'
    //         }}>
                
    //         </View>
    //         <Card
    //             style={{
    //               // flex: 1,
    //               height: 300,
    //               width: 100,
    //               backgroundColor: 'red',
    //         }}>

    //         </Card>
    //     </View>
    //   </View>
    // );
    return (
        <View style={{
            // flex:1,
            flexDirection: window.width < 1100 ? 'column' : 'row',
            
            // height: '100%',
            padding: 10,
            width: '100%',
            alignItems: 'center',
            // alignContent: 'center',
            justifyContent: window.width < 1100 ? 'flex-start' : 'center',
            // minHeight: SCREEN_SIZE.height, // Ensures the container takes up at least the full viewport height
            backgroundColor: COLORS.primaryBgColor,
            // borderWidth: 5,
            // borderColor: 'yellow',
        }}>
          <View style={{
              // ...styles.signUpForm,
              // flex: 1,
              padding: 10,
              height: SCREEN_SIZE.height*.9,
              // width: window.width < 1100 ? '100%' : '40%',
              width: '80%',
              // borderWidth: 5,
              // borderColor: 'black',
          }}>
              <Image
                  style={{
                    alignSelf: 'center',
                      width: '75%',
                      maxWidth: 400,
                      height: '30%',
                      // width: 100,
                      // height: 100,
                      // borderColor: 'yellow',
                      // borderWidth: 5
                    }}
                    resizeMode='contain'
                  source={require('../assets/alwayslocal-banner.png')}
              />
              <View style={{
                flex: 1,
                // borderColor: 'green', borderWidth: 2,
              }}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text style={{fontSize: 35,}}>
                    Hospitality Platform Coming Soon...
                    </Text>
                    {/* <CustomButton
                      onPress={()=>navigation.push('WebSignUpPage')}
                      style={{...styles.ctaBtnStyle, padding: 10, height: 70, width: 160, alignSelf: 'flex-end'}}
                      textStyle={{
                          width: '100%', 
                          fontSize: 25,
                          fontWeight: '1000',
                          alignSelf: 'flex-end',
                          color: 'white'
                      }}
                      title={"Sign Up"}
                    /> */}
                </View>
                <Text style={{fontSize: 25}}>
                    <Text style={{fontWeight: '500'}}>Always Local</Text> is a 100% Free App to build & promote all aspects of the Hospitality Industry on one platform.
                </Text>
                <View style={{
                    flexDirection: 'row', justifyContent: 'space-between',
                    // borderWidth: 2, borderColor: 'green'
                }}>
                    <TouchableContainer onPress={()=>alert("Apps coming soon! 😉")} style={{alignSelf: 'center', flexDirection: 'row', padding: 5, justifyContent: 'space-around'}}>
                      <View style={{...styles.appStoreBtn, backgroundColor: 'black', marginRight: 10}}>
                          <AntDesign style={{alignSelf: 'center'}} name="apple1" size={window.width < 1100 ? 12 : 24} color="white" />
                          <Text style={{marginLeft: 5, alignSelf: 'center', color: 'white'}}>App Store</Text>
                      </View>
                      <View style={{...styles.appStoreBtn, padding: 2.5, backgroundColor: 'black'}}>
                          <AntDesign style={{alignSelf: 'center'}} name="play" size={window.width < 1100 ? 12 : 24} color="white" />
                          <Text style={{marginLeft: 5, alignSelf: 'center', color: 'white',}}>Google Play</Text>
                      </View>
                    </TouchableContainer>

                    <View
                      style={{
                        // alignSelf: 'center',
                        flex: 1, flexDirection: 'row',
                        maxWidth: 120,
                        // borderWidth: 2, borderColor: 'yellow',
                        padding: 10, justifyContent: 'space-around'
                    }}>
                        <TouchableContainer onPress={()=>Linking.openURL('https://www.facebook.com/AlwaysLocalApp')}>
                          <Image
                              resizeMode='contain'
                              style={styles.socialMediaIcon}
                              source={require('../assets/facebook-icon.png')}
                          />
                        </TouchableContainer>
                        <TouchableContainer onPress={()=>Linking.openURL('https://www.instagram.com/alwayslocalapp/')}>
                          <Image
                              resizeMode='contain'
                              style={styles.socialMediaIcon}
                              source={require('../assets/instagram-icon.png')}
                          />
                        </TouchableContainer>
                    </View>
                </View>
                <Text style={{fontSize: 25, fontWeight: '500', textAlign: 'right'}}>
                    Feel free to Follow, Like and Tag us!
                </Text>
              </View>
          </View>
          {/* <Card
              style={{
                // flex: 1,
                padding: 10,
                height: isMobile() ? SCREEN_SIZE.height*1.05 : SCREEN_SIZE.height*.75,
                width: isMobile() ? '100%' : '40%',
                // borderWidth: 5,
                // borderColor: 'red',
                // height: 100,
                // width: 100,
              // ...styles.signUpForm, justifyContent:'space-between',

              // borderWidth: 2, borderColor: 'blue'
          }}>
              <Text style={{fontSize: 45, textAlign: 'left', alignSelf: 'flex-start', padding: 20, paddingLeft: 0}}>
                  Stay Connected!
              </Text>
              <Text style={{fontSize: 20}}>
                  Enter your information below to get launch date & updates either by email or text to the brand new Always Local App where you can get the latest info about your local Bars, Restaurants, Brands, Staff, Entertainment and more all on one Free easy to use platform!
              </Text>
              <SignUpSheet inputs={signUpInputs} state={state} setState={setState} />
              <CustomButton
                style={styles.ctaBtnStyle}
                textStyle={{
                    fontSize: 20,
                    alignSelf: 'center',
                    color: 'white'
                }}
                onPress={onSubmitForm}
                title={"Submit"}
              />
          </Card> */}
      </View>
    );
  }
  
  const styles = StyleSheet.create({
    container: {
      // flex: 1,
      // height: '100%',
      // width: '100%',
      backgroundColor: '#F8FAFC',
      // alignItems: 'center',
      // justifyContent: 'center',


      // borderColor: '#000',
      // borderWidth: 1,
    },
    formContainer: {
      // flex: 1,
      // height: '100%',
      // width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      // borderWidth: 10,
      // borderColor: 'purple'
    },
    signUpForm:{
      // flex: 1,
      // height: '100%',
      // width: '90%',
      // height: '90%',
      // height: SCREEN_SIZE.height * .85,
      // maxWidth: '40%',
      // flex: 1,
      // alignItems: 'center',
      // padding: 20,
// 
      // borderWidth: 1,
      // borderColor: 'red',
    },
    appStoreBtn: {
      justifyContent: 'center',
      borderRadius: 10,
      height: 40,
      minWidth: 100,
      width: '50%',
      backgroundColor: 'white',
      flexDirection: 'row',
    },
    socialMediaIcon: {
      width: 40,
      height: 40,
    },
    labelStyle:{
      fontSize: 20,
    },
    inputStyle: {
      height: 40,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: '#CED4DA',
      backgroundColor: '#F8FAFC',
    },
    ctaBtnStyle: {
      borderRadius: 5,
      alignSelf: 'flex-start',
      backgroundColor: '#DD222E',
      width: 120,
      height: 50,
      justifyContent: 'center',
    }
  });