import { useFonts } from "expo-font"
import { Image, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import Card from "../../components/Card"
import { COLORS, SCREEN_SIZE } from "../../Constants"
// import Colors from "../../Constants/Colors"
import ActivityIndicator from '../../components/ActivityIndicator';
import CustomButton from "../../components/CustomButton";
import { SafeAreaView } from 'react-native-safe-area-context';
import React from 'react';
import Fire from "../../Fire";
import TouchableBackButton from "../../components/TouchableBackButton";
import catchErrorForSentry from "../../helpers/catchSentryError";
import { useDispatch } from "react-redux";
import { setUserData, updateUserDataProperty } from "../../store/slices/userData";
const firebase = Fire.getFirebase();
// import Colors from "../../fonts/TT_Norms/TTNorms-"
const Header = (props: any) =>{
    const dispatch = useDispatch();
    const window = useWindowDimensions();
    const {navigation, isLoggedIn, route} = props
    const isWebLoginPage = route.name === 'WebLoginPage';
    const [processing, setProcessing] = React.useState(false);
    // let [fontsLoaded] = useFonts({
    //     'TT-Norms': require('../../fonts/TT_Norms/TTNorms-Regular.otf'),
    // });
    // if(!fontsLoaded) return <ActivityIndicator/>;
    return(
        <SafeAreaView style={{ flex: 1 }}>

            <Card
            style={{
                // flex: 1,
                flexDirection: 'row',
                height: window.height * .1,
                // width: window.width,
                padding: 10,
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius:0,
                borderBottomRadius: 5,
                // borderWidth: 1, 
                // borderColor: 'red'

            }}>
                <Image
                    style={{
                        height: '100%',
                        width: SCREEN_SIZE.width * .25,
                    }}
                    resizeMode='contain'
                    source={require('../../assets/alwayslocal-banner.png')}
                />
                <View style={{
                    width: isWebLoginPage ? '100%' : '15%',
                    minWidth: 250,
                    flexDirection: 'row',
                    alignSelf: 'center',
                    alignItems: isWebLoginPage ? 'center' : 'flex-end',
                    justifyContent: isWebLoginPage ? 'space-between' : 'space-around',

                    // borderWidth: 1,
                    // borderColor: 'black',
                }}>
                    {!isWebLoginPage && <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                        <CustomButton
                            style={styles.ctaBtnStyle}
                            textStyle={{
                                fontSize: 20,
                                // alignSelf: 'center',
                                color: 'white'
                            }}
                            title={isLoggedIn ? "Log out" : "Login"}
                            onPress={()=>{
                                // console.log("Logging in");
                                const page = isLoggedIn ? "Home" : "WebLoginPage"
                                if(!isLoggedIn) return navigation.push(page);
                                setProcessing(true);
                                firebase.auth().signOut().then(function() {
                                    // Sign-out successful.
                                    console.log("Sign Out successful");
                                    setProcessing(false);
                                    //flushing redux datanot working
                                    //Note I think the problem was not using props. as illustrated below
                                    // setGlobalCurrentUserData({});
                                    // setGlobalCurrentUserData({});
                                    // setGlobalChatData({});
                                    dispatch(setUserData({}))
                                    navigation.push(page)
                                }).catch(function(error) {
                                    console.log("Error: ", error);
                                    console.log("Error signing out user JSON: ", JSON.stringify(error)); 
                                    catchErrorForSentry(error);
                                    alert("Error signing you out!")
                                    // Sentry.captureException("Error signing out user: ");
                                    // An error happened.
                                });
                            }}
                        />
                        {
                            !isLoggedIn &&
                            (
                                processing
                                ?
                                <ActivityIndicator size="large" color={COLORS.primaryRed}/>
                                :
                                <CustomButton
                                    style={{...styles.ctaBtnStyle, marginLeft: 10}}
                                    textStyle={{
                                        fontSize: 15,
                                        // alignSelf: 'center',
                                        color: 'white'
                                    }}
                                    title={"Create Account"}
                                    onPress={()=>{
                                        // console.log("Logging in");
                                        navigation.push('WebSignUpPage')
                                }}/>
                            )
                        }
                    </View>}
                </View>
            </Card>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    ctaBtnStyle: {
      borderRadius: 5,
      backgroundColor: '#DD222E',
      width: 120,
      height: 50,
      justifyContent: 'center',
    }
  });

export default Header