import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
// import getImageUploadUrl from './getImageUploadUrl';
import getMediaUploadUrl from './getMediaUploadUrl';
import catchErrorForSentry from './catchSentryError';

async function uploadFileWeb(
    pathToImageFile: string,
    signedUrl: string,
    onSuccess?: Function,
    onFailure?: Function
  ) {
    // Fetch the file data from the local file URI
    const fileData = await fetch(pathToImageFile);
    const fileBlob = await fileData.blob();
  
    // Get the content type from the fileBlob
    const contentType = fileBlob.type;
    // console.log("Content type: ", contentType);
    // Upload the file using the Fetch API
    try {
      const response = await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // "Access-Control-Allow-Headers": "Content-Type",
        },
        body: fileBlob,
      });
  
      // Handle the response according to your API
      if (response.ok) {
        onSuccess && onSuccess(response);
        return true;
      } else {
        onFailure && onFailure(await response.text());
        // alert("Response text error");
        return false;
      }
    } catch (error) {
      onFailure && onFailure(error);
      console.log("JSON stringified format: ", JSON.stringify(error)); 
      catchErrorForSentry(error);
      // alert("general error");
      return false;
    }
  }

  const uploadFileMobile = (pathToImageFile: string, signedUrl: string, onSuccess?: Function, onFailure?: Function)=>{
    //Not sure why this works for videos too, but apparently it does?
    let options: FileSystem.FileSystemUploadOptions = {
        mimeType: 'image/jpeg',
        httpMethod: 'PUT',
    };

    return FileSystem.uploadAsync(signedUrl, pathToImageFile, options)
    .then(async (response)=>{
        (onSuccess && onSuccess(response));
        return true;
    })
    .catch((error)=>{
        console.log("Error uploading image uploadFileMobile: ", JSON.stringify(error)); 
        catchErrorForSentry(error);
        (onFailure && onFailure(error));
        return false;
    })
  }

const uploadToStorage = async (pathToImageFile: string, imageKey: string, objectType: 'image' | 'video', onSuccess?: Function, onFailure?: Function) => {
    
    const signedUrl = await getMediaUploadUrl(imageKey, objectType);
    // alert(`SignedUrl:  ${signedUrl}`);
    if(Platform.OS ==='web') return uploadFileWeb(pathToImageFile, signedUrl, onSuccess, onFailure);
    return uploadFileMobile(pathToImageFile, signedUrl, onSuccess, onFailure);
    
}

export default uploadToStorage