import Fire from "../Fire";
import catchErrorForSentry from "./catchSentryError";
const firebase = Fire.getFirebase();

function updateCurrentUserPhonenumber(phoneCredential: firebase.auth.AuthCredential, onSuccess?: any, onFailure?: any) {
    const user = firebase.auth().currentUser;
  
    if (user) {
      return user.updatePhoneNumber(phoneCredential)
        .then((success: any) => {
          onSuccess(success);
        })
        .catch((error: any) => {
          catchErrorForSentry(error);
          onFailure(error)
        });
    } else {
      onFailure('No user is signed in')
      throw new Error('No user is signed in.');
    }
}

  export default updateCurrentUserPhonenumber;