import { StyleSheet, View, Text, Image, ActivityIndicator, TextInput, ScrollView, useWindowDimensions } from "react-native";
import { useState, useRef, memo } from 'react';
import Card from "../components/Card";
import SignUpSheet from "../navigation/components/SignUpSheet";
import HomeHeader from "../navigation/components/HomeHeader";
import { ValidateInputInterface } from "../Interfaces";
import { COLORS, regexPatterns } from "../Constants";
import UploadPictureModal from "../components/UploadPictureModal";
import CustomButton from "../components/CustomButton";
import { Ionicons } from "@expo/vector-icons";
import TouchableBackButton from "../components/TouchableBackButton";
import AccountChoosePictures from "../components/AccountChoosePictures";
import ElementSlideshow from "../components/ElementSlideshow";
import isPhoneNumberTaken from "../helpers/isPhoneNumberTaken";
import { FirebaseRecaptchaVerifierModal, FirebaseRecaptcha } from 'expo-firebase-recaptcha';
import Fire from "../Fire";
import isAccountInformationTaken from "../helpers/IsAccountInformationTaken";
import ValidateInput from "../components/ValidateInput";
import ConfirmSMSBox from "../components/ConfirmSMSBox";
import isMobile from "../helpers/isMobile";
import AlwaysLocalLogo from "../components/AlwaysLocalLogo";
import { setUserData } from "../store/slices/userData";
import { useDispatch } from "react-redux";
import catchErrorForSentry from "../helpers/catchSentryError";
const firebase = Fire.getFirebase();


const WebSignUpPage = (props: any)=>{
    // const window = useWindowDimensions();
    const dispatch = useDispatch();
    const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
    const [funnelState, setFunnelState] = useState<"Brand" | "User" | "Account" | "">("");
    const [pictureMode, setPictureMode] = useState(false);
    const [smsCode, setSmsCode] = useState("");
    const [processing, setProcessing] = useState(false);
    const [confirmingSMSCode, setConfirmingSMSCode] = useState(false);

    const recaptchaVerifier = useRef(null);
    const [verificationId, setVerificationId] = useState(null);
    const [verifyingRecaptcha, setVerifyingRecaptcha] = useState(false);
    
    const [state, setState] = useState<any>({
        username: "",
        // username: "bar1",
        // username: "tyfoods",
        usernameError: 'Usernames can only contain up to 30 of these characters: a-z, 0-9, _ and . (only in the middle)',
    
        firstName: "",
        firstNameError: 'First names must start with a capital letter and can only contain up to 30 characters',
    
        lastName: "",
        lastNameError: 'Last names must start with a capital letter and can only contain up to 30 characters',
    
        phoneNumber: "",
        // phoneNumber: "+15617565951",
        // phoneNumber: "+15616742253",
        phoneNumberError: 'Phone numbers must be of the form "+ (country code) (area code) (number)" ',
        countryCode: "+1",
    
        email: "",
        // email: "anthonecuthill@gmail.com",
        // email: "tyfooods@gmail.com",
        emailError: 'Email must be valid',
        
        password: "",
        // password: "alwayslocal",
        passwordError: 'Password must be greater than 6 characters',
    
        smsCode: "",
        error: "",
        loading: false,
    
        usernameErrorFlag: false,
        nameFlag: false,
        emailErrorFlag: false,
        phoneNumberErrorFlag: false,
        passwordErrorFlag: false,
    });

    const signUpSheetInputs: ValidateInputInterface[] = [
        // {
        //     name: 'First Name',
        //     errorMessage: state.firstNameError,
        //     errorName: 'nameFlag',
        //     onInputChange: (value: string)=>setState((prevState: any)=>{prevState.firstName = value; return prevState;}),
        //     regex: regexPatterns.name,
        //     defaultValue: state.firstName || '',
        // },
        // {
        //     name: 'Last Name',
        //     errorMessage: state.firstNameError,
        //     errorName: 'nameFlag',
        //     onInputChange: (value: string)=>setState((prevState: any)=>{prevState.lastName = value; return prevState;}),
        //     regex: regexPatterns.name,
        //     defaultValue: state.lastName || '',
        // },
        {
            name: 'Username',
            errorMessage: state.usernameError,
            errorName: 'nameFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.username = value; return prevState;}),
            regex: regexPatterns.username,
            defaultValue: state.username || '',
        },
        {
            name: 'Phone Number',
            errorMessage: state.phoneNumberError,
            errorName: 'phoneNumberErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.phoneNumber = value; return prevState;}),
            regex: regexPatterns.phoneNumber,
            defaultValue: state.phoneNumber || '',
        },
        {
            name: 'Email',
            errorMessage: state.emailError,
            errorName: 'emailErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;}),
            regex: regexPatterns.email,
            defaultValue: state.email || '',
        },
        {
            name: 'Password',
            errorMessage: state.passwordError,
            errorName: 'passwordErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.password = value; return prevState;}),
            regex: regexPatterns.password,
            defaultValue: state.password || '',
            props: {
                secureTextEntry: true
            },
        },
    ]


    const renderChooseAccountTitle = ()=>{
        return(
            <View style={{
                // flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                // borderColor: 'green',
                // borderWidth: 1
            }}>
                <AlwaysLocalLogo/>
                <Text style={{fontSize: 25, alignSelf: 'center', fontWeight: '500', marginBottom: 10}}>
                    Create your profile early and get notified when we launch!
                </Text>
                <Text style={{fontSize: 15, alignSelf: 'center'}}>
                    What kind of account are you making?
                </Text>
            </View>
        )
    }
    const ChooseAccountType = ()=>{
        return(
            <>
                {renderChooseAccountTitle()}
                <View style={{
                    width: '100%',
                    maxWidth: 500,
                    height: isMobile() ? 300 : 150,
                    // flex: 2,
                    justifyContent: 'space-around',
                    alignItems: 'center', 
                    flexDirection: isMobile() ? 'column' : 'row',
                    // borderWidth: 1,
                    // borderColor: 'red',
                }}>
                    {/* <CustomButton
                        onPress={()=>{setFunnelState("Brand");}}
                        style={styles.accTypeBtn}
                        textStyle={styles.accTypeBtnTxt}
                        title={"Brand"}
                    /> */}
                    <CustomButton
                        onPress={()=>{setFunnelState("Account");}}
                        style={styles.accTypeBtn}
                        textStyle={styles.accTypeBtnTxt}
                        title={"Account"}
                    />
                    {/* <CustomButton
                        onPress={()=>{setFunnelState("User");}}
                        style={styles.accTypeBtn}
                        textStyle={styles.accTypeBtnTxt}
                        title={"Local"}
                    /> */}
                </View>
            </>
        )
    }

    const sendVerification = (phoneNumber: string) => {
        setVerifyingRecaptcha(true);
        const phoneProvider = new firebase.auth.PhoneAuthProvider();
        // console.log("Sending SMS");
        phoneProvider
            //@ts-ignore
            .verifyPhoneNumber(phoneNumber, recaptchaVerifier.current)
            //@ts-ignore
            .then((token: any)=>{
                setVerificationId(token);
                setConfirmingSMSCode(true);
                setVerifyingRecaptcha(false);
            })
            .catch((error)=>{
    
            if(error.code === 'ERR_FIREBASE_RECAPTCHA_CANCEL'){
              alert('You must complete the captcha to continue!');
            }
            else if(error.code === 'auth/invalid-phone-number'){
              alert("Your phone number has an invalid format")
            }
            else if(error.code === 'auth/captcha-check-failed'){
              alert("There was an issue processing your captcha, please try again!")
            }
            else{
              alert("There was an error verifying your phone number. Try again, or please email support@alwayslocal.net for help!")
              console.log("Error verifying captcha: ", error);
              console.log("Error verifying phone number: ", JSON.stringify(error)); 
              catchErrorForSentry(error);
            }
            setVerifyingRecaptcha(false);
    
            // https://firebase.google.com/docs/reference/js/firebase.auth.PhoneAuthProvider
            // auth/missing-phone-number
            // Thrown if the phone number is missing.
            // auth/quota-exceeded
            // Thrown if the SMS quota for the Firebase project has been exceeded.
            // auth/user-disabled
            // Thrown if the user corresponding to the given phone number has been disabled.
            // auth/maximum-second-factor-count-exceeded
            // Thrown if The maximum allowed number of second factors on a user has been exceeded.
            // auth/second-factor-already-in-use
            // Thrown if the second factor is already enrolled on this account.
            // auth/unsupported-first-factor
            // Thrown if the first factor being used to sign in is not supported.
            // auth/unverified-email
            // Thrown if the email of the account is not verified.
         
          })
    };
    
    const confirmSMSCode = (smsCode: number | string) => {
    // setProcessing(true);
    const credential = firebase.auth.PhoneAuthProvider.credential(
        //@ts-ignore
        verificationId,
        smsCode
    );
    firebase
        .auth()
        .signInWithCredential(credential)
        .then(async (result) => {
        // Do something with the results here

        //TODO: Delete this, it doesn't matter anymore.
        // setPictureMode(true);

        setConfirmingSMSCode(false);
        setProcessing(false);
        console.log("Result of confirmed SMS code: ", result);
        await linkAccountToLoginCredentials();
        }).catch((error)=>{
            alert("There was a problem confirming your SMS code! - Please try again.");
            setProcessing(false);
            console.log("Error: ", error);
            console.log("Error: ", JSON.stringify(error));
        //   Sentry.captureException(`Problem confirming SMS code, error is: ${error}`);
            // setConfirmingSMSCode(false);
        })
    }

    const initDatabaseUserData=(userData: any)=>{
        const {uid: userId, phoneNumber} = userData;
        let strippedUserData = {...userData}
        delete strippedUserData.phoneNumber;

        console.log("UserData: ", strippedUserData);
        firebase.database().ref(`publicUserData/${userId}`).set(strippedUserData)
        console.log("phoneNumber: ", phoneNumber);
        firebase.database().ref(`phoneNumbers/${userId}`).set(phoneNumber);
    }

    const linkAccountToLoginCredentials = async ()=>{
    const {username, password, phoneNumber} = state;
    let {email} = state;
    email = email.trim();
    // user is already signed in with phoneNumber, so linking user account with provided information.
    var credential = firebase.auth.EmailAuthProvider.credential(email, password);
    //@ts-ignore
    await firebase.auth().currentUser.linkWithCredential(credential).then( async (usercred)=> {
        var user = usercred.user;
        console.log("Account linking success", user);
        console.log("User.uid: ", user.uid);
        console.log("email: ", email);
        console.log("Should init database user data now");
        let userData = {
            accountType: funnelState.toLowerCase(),
            username: username,
            displayName: username,
            uid: user.uid,
            email: email,
            bio: "",
            profilePictureKey: false,
            coverPhotoKey: false,
            uidsFollowingUser: false,
            uidsUserFollows: false,
            phoneNumber: phoneNumber,


            facebookUrl: "",
            instagramUrl: "",
            pictureKeys: {},
            entertainmentPictureKeys: {},
            address: "",
            coords: {},
            dailySpecials: [],
            weeklySpecials: [],
            monthlySpecials: [],
            dealOne: {},
            dealTwo: {},
            monthlySpecialsImageKey: "",
        }
        initDatabaseUserData(userData);
        dispatch(setUserData(userData));
        props.navigation.navigate('WebBackEndPage');
        // await Updates.reloadAsync();

    }, (error)=> {
        console.log("Error: ", error);
        console.log("Account linking error", JSON.stringify(error));
        // Sentry.captureException("Account linking error");
        alert("There was a problem signing you up please try again!");
        setProcessing(false);
    });
    }


    const isFormDataValid = ()=>{
        const {firstName, lastName, email, password, phoneNumber, emailErrorFlag, phoneNumberErrorFlag, passwordErrorFlag} = state;
        if(firstName || lastName || email || password || phoneNumber) return true;
        if(!emailErrorFlag || !passwordErrorFlag || !phoneNumberErrorFlag) return true;
    }

    const onCreateAccount = (event: React.MouseEvent<HTMLButtonElement>)=>{
        event.preventDefault();
        if(!isFormDataValid()) return alert("There is a problem with your form information");
        const {username, email, password, phoneNumber} = state;
        //TODO: Country code should be seperately parsed, so I can do something with it?
        setProcessing(true);
        isAccountInformationTaken(phoneNumber, email, username)
        .then(async (response: any)=>{
            let { isPhoneNumberTaken, isUsernameTaken, isEmailTaken } = JSON.parse(response);

            if(isPhoneNumberTaken || isUsernameTaken || isEmailTaken){
              const alertText = (()=>{
                if(isPhoneNumberTaken) return 'There is already an account with that phone number'
                if(isUsernameTaken) return 'There is already an account with that username'
                if(isEmailTaken) return 'There is already an account with that email'
              })()
              setProcessing(false);
              alert(alertText);
              return;
            }
            else{
                setProcessing(false);
                sendVerification(phoneNumber)
            }
        })
        .catch((error)=>{
            setProcessing(false);
            console.log("Error creating account WebSignUpPage: ", error);
            console.log("Error creating account WebSignUpPage: ", JSON.stringify(error)); 
            catchErrorForSentry(error);
        })
    }

    const SignUp = ()=>{
        return(
            <>
                <AlwaysLocalLogo style={{top:0}}/>
                <View style={{width: '80%'}}>
                    <SignUpSheet inputs={signUpSheetInputs} state={state} setState={setState} />
                    <CustomButton
                        onPress={(event: React.MouseEvent<HTMLButtonElement>)=>onCreateAccount(event)}
                        style={{
                            ...styles.accTypeBtn,
                            alignSelf: 'center',
                            marginTop: 30
                        }}
                        textStyle={styles.accTypeBtnTxt}
                        title={"Create Account"}
                    />
                </View>
            </>
        )
    }

    const PictureHandler = ()=>{
        if(funnelState === "User"){
            return(
                <>
                    Picture UI
                </>
            )
        }
        return <AccountChoosePictures/>
    }


    const renderContent = ()=>{
        if(confirmingSMSCode) return (
            <ConfirmSMSBox
                phoneNumber={state.phoneNumber}
                confirmSMSCode={confirmSMSCode}
                confirmingSMSCode={confirmingSMSCode}
                sendVerification={sendVerification}
                smsCode={smsCode}
                setSmsCode={setSmsCode}
            />
        )
        if(verifyingRecaptcha) return(
                <FirebaseRecaptchaVerifierModal
                    ref={recaptchaVerifier}
                    firebaseConfig={firebase.app().options}
                /> 
        )
        if(processing) return <ActivityIndicator color={COLORS.primaryRed} size="large"/>
        if(pictureMode) return <PictureHandler/>
        if(!funnelState) return <ChooseAccountType/>
        if(funnelState) return <SignUp/>
    }

    return(
        <View style={styles.container}>
            {/* <FirebaseRecaptchaVerifierModal
                ref={recaptchaVerifier}
                firebaseConfig={firebase.app().options}
            /> */}
            <UploadPictureModal
                showModal={showUploadPictureModal}
                setShowModal={setShowUploadPictureModal}
                onPictureUploaded={()=>console.log("Picture has uploaded")}
                picturePrefix={`prefix`}
                selectedImageKey={''}
                onRemoveSelectedImage={()=>console.log("Selected image removed")}
            />
            <TouchableBackButton
                onPress={()=>props.navigation.pop()}
                containerStyle={{
                    margin: 20,
                    position: 'absolute',
                    left: '0',
                    top: '0'
                }}
                size={30}
            />
            <Card style={{
                ...styles.signUpForm,
                justifyContent: 'center',
                alignItems: 'center', 
                padding: 10,
            }}>
                {funnelState ?
                    <TouchableBackButton
                        onPress={()=>{
                            if(pictureMode) setPictureMode(false)
                            else setFunnelState("");
                        }}
                        containerStyle={{
                            margin: 20,
                            position: 'absolute',
                            left: '0',
                            top: '0'
                        }}
                        size={30}
                    />
                : null}
                {renderContent()}
            </Card>
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#F8FAFC',
        alignItems: 'center',
        justifyContent: 'center',
    },
    accTypeBtn:{
        borderRadius: 10,
        backgroundColor: '#DD222E',
        width: 150,
        height: 70,
        justifyContent: 'center',
    },
    accTypeBtnTxt:{
        fontSize: 20,
        alignSelf: 'center',
        color: 'white',
        fontWeight: "500",
    },
    signUpForm: {
        // flex: 1,
        height: '80%',
        width:'80%',
    }
})

export default WebSignUpPage