import Navigation from './navigation';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';
import Strings from './Constants/Strings';
import { Provider } from 'react-redux';
import store from './store';
import * as Sentry from 'sentry-expo';

console.log("Sentry: ", Sentry);

Sentry.init({
  dsn: 'https://99cfaf13e8fb4990a29457baf1064d99@o360186.ingest.sentry.io/4505280069042176',
  enableInExpoDevelopment: false,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});
const i18n = new I18n(Strings);
const locale = Localization.locale
i18n.locale = locale;
// console.log("i18n: ", i18n);
i18n.enableFallback = true;

export default function App() {

    return (
      <Provider store={store}>
        <Navigation/>
      </Provider>
    );
}

// export default function App() {

//   const [state, setState] = useState<any>({
//     username: "",
//     usernameError: 'Usernames can only contain up to 30 of these characters: a-z, 0-9, _ and . (only in the middle)',

//     firstName: "",
//     firstNameError: 'First names must start with a capital letter and can only contain up to 30 characters',

//     lastName: "",
//     lastNameError: 'Last names must start with a capital letter and can only contain up to 30 characters',

//     phoneNumber: "",
//     phoneNumberError: 'Phone numbers must be of the form "+ (country code) (area code) (number)" ',
//     countryCode: "+1",

//     email: "",
//     emailError: 'Email must be valid',
    
//     password: "",
//     passwordError: 'Password must be greater than 6 characters',

//     smsCode: "",
//     error: "",
//     loading: false,

//     usernameErrorFlag: false,
//     nameFlag: false,
//     emailErrorFlag: false,
//     phoneNumberErrorFlag: false,
//     passwordErrorFlag: false,
//   });

//   const flagError = (flagName: string, flag: boolean)=>{
//     setState((prevState: any)=>{
//       prevState[`${flagName}`] = flag;
//       return prevState;
//     })
//   }

//   return (
//     <View style={styles.container}>
//       <View style={styles.formContainer}>
//         <View style={{
//             ...styles.signUpForm,
//         }}>
//           {/* ADD LOGO HERE */}
//           <Image
//               resizeMode='contain'
//               style={{
//                   alignSelf: 'flex-start',
//                   width: '65%',
//                   height: '40%',
//                   // borderWidth: 5,
//                   // borderColor: 'yellow',
//               }}
//               source={require('./assets/alwayslocal-logo.png')}
//           />

//           <View style={{
//             // borderColor: 'white', 
//             // borderStyle: 'solid', borderWidth: 2
//           }}>
//             <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
//               <Text style={{fontSize: 65,}}>
//                 Hospitality Platform Coming Soon...
//               </Text>
//               <CustomButton
//                 style={{...styles.ctaBtnStyle, padding: 10, height: 70, width: 160, alignSelf: 'flex-end'}}
//                 textStyle={{
//                   // borderColor: 'white',
//                   // borderWidth: 2,
//                   width: '100%', 
//                   // height: '100%',
//                   fontSize: 25,
//                   // flex: 1,
//                   // padding: 10,
//                   fontWeight: '1000',
//                   alignSelf: 'flex-end',
//                   color: 'white'
//                 }}
//                 title={"Sign Up"}
//               />
//             </View>
//             <Text style={{fontSize: 25}}>
//               <Text style={{fontWeight: '500'}}>Always Local</Text> is a 100% Free App to build & promote all aspects of the Hospitality Industry on one platform.
//             </Text>
//             {/* Social media Bar */}
//             <View style={{
//               flexDirection: 'row', justifyContent: 'space-between',
//               // borderWidth: 2, borderColor: 'yellow'
//             }}>
//               <View style={{alignSelf: 'center', flexDirection: 'row', padding: 5, justifyContent: 'space-around'}}>
//                 <View style={{...styles.appStoreBtn, backgroundColor: 'black', marginRight: 10}}>
//                   <AntDesign style={{alignSelf: 'center'}} name="apple1" size={24} color="white" />
//                   <Text style={{marginLeft: 5, alignSelf: 'center', color: 'white'}}>App Store</Text>
//                 </View>
//                 <View style={{...styles.appStoreBtn, backgroundColor: 'black'}}>
//                   <AntDesign style={{alignSelf: 'center'}} name="play" size={24} color="white" />
//                   <Text style={{marginLeft: 5, alignSelf: 'center', color: 'white',}}>Google Play</Text>
//                 </View>

//               </View>
//               <View style={{
//                 alignSelf: 'center', width: 125, flexDirection: 'row',
//                 // borderWidth: 2, borderColor: 'yellow',
//                 padding: 10, justifyContent: 'space-around'
//               }}>
//                 {/* <TouchableWithoutFeedbackComponent> */}
//                   <Image
//                       resizeMode='contain'
//                       style={styles.socialMediaIcon}
//                       source={require('./assets/facebook-icon.png')}
//                   />
//                 {/* </TouchableWithoutFeedbackComponent> */}
//                 {/* <TouchableWithoutFeedbackComponent> */}
//                   <Image
//                       resizeMode='contain'
//                       style={styles.socialMediaIcon}
//                       source={require('./assets/instagram-icon.png')}
//                   />
//                 {/* </TouchableWithoutFeedbackComponent> */}
//               </View>
//             </View>
//             <Text style={{fontSize: 25, fontWeight: '500', textAlign: 'right'}}>
//                 Feel free to Follow, Like and Tag us!
//             </Text>
//           </View>
//         </View>
//         <Card
//           style={{
//             ...styles.signUpForm, justifyContent:'space-between',
//           // borderWidth: 2, borderColor: 'blue'
//         }}>
//           <View>
//             <Text style={{fontSize: 45, textAlign: 'left', alignSelf: 'flex-start', padding: 20, paddingLeft: 0}}>
//               Stay Connected!
//             </Text>
//             <Text style={{fontSize: 20}}>
//               Enter your information below to get launch date & updates either by email or text to the brand new Always Local App where you can get the latest info about your local Bars, Restaurants, Brands, Staff, Entertainment and more all on one Free easy to use platform!
//             </Text>
//             <ValidateInput
//               id="first-name"
//               label="First Name"
//               regex={regexPatterns.username}
//               errorMessage={state.firstName}
//               labelStyle={styles.labelStyle}
//               inputStyle={styles.inputStyle}
//               flagError={(flag: boolean)=>flagError("firstNameErrorFlag", flag)}
//               errorName={"firstName"}
//               onInputChange={(firstName: string)=>{
//                 // console.log("Email: ", email);
//                 setState((prevState: any)=>{
//                   prevState.firstName = firstName;
//                   return prevState;
//                 });
//                 // console.log("email state: ", state.email);
//               }}
//               defaultValue={state.email}
//             />
//             <ValidateInput
//               id="last-name"
//               label="Last Name"
//               regex={regexPatterns.name}
//               errorMessage={state.emailError}
//               labelStyle={styles.labelStyle}
//               inputStyle={styles.inputStyle}
//               flagError={(flag: boolean)=>flagError("lastNameErrorFlag", flag)}
//               errorName={"lastName"}
//               onInputChange={(lastName: string)=>{
//                 // console.log("Email: ", email);
//                 setState((prevState: any)=>{
//                   prevState.lastName = lastName;
//                   return prevState;
//                 });
//                 // console.log("email state: ", state.email);
//               }}
//               defaultValue={state.email}
//             />
//             <ValidateInput
//               id="email"
//               label="Email"
//               regex={regexPatterns.email}
//               errorMessage={state.emailError}
//               labelStyle={styles.labelStyle}
//               inputStyle={styles.inputStyle}
//               flagError={(flag: boolean)=>flagError("emailErrorFlag", flag)}
//               errorName={"email"}
//               onInputChange={(email: string)=>{
//                 // console.log("Email: ", email);
//                 setState((prevState: any)=>{
//                   prevState.email = email;
//                   return prevState;
//                 });
//                 // console.log("email state: ", state.email);
//               }}
//               defaultValue={state.email}
//             />
//             <ValidateInput
//               id="phone-number"
//               label="Phone Number"
//               regex={regexPatterns.phoneNumber}
//               errorMessage={state.phoneNumberError}
//               labelStyle={styles.labelStyle}
//               inputStyle={styles.inputStyle}
//               flagError={(flag: boolean)=>flagError("phoneNumberErrorFlag", flag)}
//               errorName={"phoneNumber"}
//               onInputChange={(phoneNumber: string)=>{
//                 // console.log("Email: ", email);
//                 setState((prevState: any)=>{
//                   prevState.phoneNumber = phoneNumber;
//                   return prevState;
//                 });
//                 // console.log("email state: ", state.email);
//               }}
//               defaultValue={state.email}
//             />
//           </View>
//           <CustomButton
//             style={styles.ctaBtnStyle}
//             textStyle={{
//               fontSize: 20,
//               alignSelf: 'center',
//               color: 'white'
//             }}
//             title={"Submit"}
//           />
//         </Card>
//       </View>
//       {/* <Text>Open up App.tsx to start working on your app!</Text>
//       <StatusBar style="auto" /> */}
//     </View>
//   );
// }

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     backgroundColor: '#F8FAFC',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   signUpForm:{
//     height: '100%',
//     width: '50%',
//     flex: 1,
//     // borderWidth: 2,
//     // borderColor: 'yellow',
//     alignItems: 'center',
//     padding: 20,
//   },
//   formContainer: {
//     // flex: 1,
//     width: '75%',
//     height: '60%',
//     flexDirection: 'row',
//     // borderColor: 'white',
//     // borderWidth: 10,
//     // borderStyle: 'solid'
//   },
//   appStoreBtn: {
//     justifyContent: 'center',
//     borderRadius: 10,
//     height: 40,
//     minWidth: 150,
//     width: '18.5%',
//     backgroundColor: 'white',
//     flexDirection: 'row',
//     // paddingVertical: 20,
//     // paddingHorizontal: 10,
//     // borderWidth: 1,
//     // borderColor: 'yellow'
//   },
//   socialMediaIcon: {
//     width: 40,
//     height: 40,
//     //TODO: Making it responsive didn't seem to work.
//     // width: '25%',
//     // height: '25%',
//   },
//   labelStyle:{
//     fontSize: 20,
//   },
//   inputStyle: {
//     height: 40,
//     borderRadius: 5,
//     borderWidth: 1,
//     borderColor: '#CED4DA',
//     backgroundColor: '#F8FAFC',
//   },
//   ctaBtnStyle: {
//     borderRadius: 5,
//     alignSelf: 'flex-start',
//     backgroundColor: '#DD222E',
//     width: 120,
//     height: 50,
//     justifyContent: 'center',
//   }
// });
