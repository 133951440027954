import { ActivityIndicator, StyleSheet, View } from "react-native"
import ValidateInput from "../../components/ValidateInput"
import { COLORS } from "../../Constants";
import { ValidateInputInterface } from "../../Interfaces";

const SignUpSheet = (props: any)=>{

   const {containerStyle, inputs, state, setState} = props;
    
    const flagError = (flagName: string, flag: boolean)=>{
        setState((prevState: any)=>{
            prevState[`${flagName}`] = flag;
            return prevState;
        })
    }

    const hyphenateName = (name: string)=>{
        return name.trim().replace(/\s+/g, '-').toLowerCase();
    }

    const generateInputs = ()=>{
        if(!inputs.length) return <ActivityIndicator color={COLORS.primaryRed} size="large"/>
        return inputs.map((input: ValidateInputInterface)=>{
            return(
                <ValidateInput
                    {...input.props}
                    key={input.name}
                    id={hyphenateName(input.name)}
                    isRequired={input.isRequired}
                    label={input.name}
                    regex={input.regex}
                    errorMessage={input.errorMessage}
                    labelStyle={styles.labelStyle}
                    inputStyle={styles.inputStyle}
                    flagError={(flag: boolean)=>flagError(input.errorName, flag)}
                    errorName={input.errorName}
                    onInputChange={input.onInputChange}
                    defaultValue={input.defaultValue}
                />
            )
        })
    }
    return (
        <View style={{marginVertical: 15, ...containerStyle}}>
            {generateInputs()}
        </View>
    )
}

const styles = StyleSheet.create({
    labelStyle:{
        fontSize: 20,
    },
    inputStyle: {
        height: 40,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#CED4DA',
        backgroundColor: '#F8FAFC',
    },
})

export default SignUpSheet