import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, Platform, ImageBackgroundBase, ImageBackground } from 'react-native';
// import Card from '../../components/Card';
// import Input from '../../components/Input';
import CacheImageBackground from './CacheImageBackground';
// import TouchableCard from '../../components/TouchableCard';
// import { cloudFrontUrl, SCREEN_SIZE, MAX_NUMBER_OF_DATING_PICTURES } from '../../Constants';
import { Ionicons } from "@expo/vector-icons";
// import { useEffect } from 'react';
// import { connect } from 'react-redux';
// import Animated, { runOnJS, useAnimatedGestureHandler, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
// import { PanGestureHandler } from 'react-native-gesture-handler';
import Fire from '../Fire'
// import deleteFromStorage from '../../helpers/deleteFromStorage';
import UploadPictureModal from './UploadPictureModal';
import { COLORS, S3_URL, SCREEN_SIZE } from '../Constants';
import TouchableContainer from './TouchableContainer';
import getUserDataAsync from '../helpers/getUserDataAsync';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import deleteFromStorage from '../helpers/deleteFromStorage';
import { updateUserDataProperty } from '../store/slices/userData';
import isVarDefined from '../helpers/isVarDefined';
const firebase = Fire.getFirebase();

const MAX_NUMBER_OF_PICTURES = 15;

const AccountChoosePictures = (props: any)=>{

    const { mode="account" } = props;
    const isAccount = mode === "account" ? true : false;

    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);

    const defaultUploadedPictures = (()=>{
        if(isAccount) return userData.pictureKeys ? userData.pictureKeys : [];
        return userData.entertainmentPictureKeys ? userData.entertainmentPictureKeys : [];
    })()
    const [uploadedPictures, setUploadedPictures] = useState<any>(defaultUploadedPictures);
    const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
    const [ currentIndex, setCurrentIndex ] = useState<any>(null)


    // const [moving, setMoving]  = useState(false);

    useEffect(()=>{
        let pictureKeysRef = firebase.database().ref(`publicUserData/${userData.uid}/pictureKeys`)
        let entPictureKeysRef = firebase.database().ref(`publicUserData/${userData.uid}/entertainmentPictureKeys`)
        if(mode==="account"){
            pictureKeysRef.on('value', (data)=>{
                const pictureKeys = data.val();
                if (pictureKeys) {
                    // const pictureData = Object.values(pictureKeys)//.map((key)=>{return `${S3_URL}/${key}`});
                    setUploadedPictures(pictureKeys);
                }
                else setUploadedPictures([])
            })
        }
        else{
            entPictureKeysRef.on("value", (data)=>{
                const pictureKeys = data.val();
                if(pictureKeys){
                    // const pictureData = Object.values(pictureKeys)//.map((key)=>{return `${S3_URL}/${key}`});
                    setUploadedPictures(pictureKeys);
                }
                else setUploadedPictures([])
            })
        }

        return () =>{
            if(mode==="account") pictureKeysRef.off();
            entPictureKeysRef.off();
        }
    }, [])

    const onPictureUploaded = (imageKey: string)=>{
        const route = isAccount ? 'pictureKeys' : 'entertainmentPictureKeys';
        // console.log("Current index on picture upload: ", currentIndex);
        firebase.database().ref(`publicUserData/${userData.uid}/${route}/${currentIndex}/`).set(imageKey);
        dispatch(updateUserDataProperty({property: route, value: isAccount ? userData.pictureKeys : userData.entertainmentPictureKeys}))
        // console.log("Picture Keys: ", userData.pictureKeys);
        setShowUploadPictureModal(false);
    }

    const onRemoveSelectedImage = ()=>{
        const route = isAccount ? 'pictureKeys' : 'entertainmentPictureKeys';
        firebase.database().ref(`publicUserData/${userData.uid}/${route}/${currentIndex}/`).remove()
        if(uploadedPictures[currentIndex] !== userData.profilePictureKey){
            console.log("Removing current index: ", uploadedPictures[currentIndex], ' from database')
            deleteFromStorage(uploadedPictures[currentIndex], "image");
        }
        //Update local state
        setUploadedPictures((prevState: any)=>{
            const updatedPictures = [...prevState];
            updatedPictures[currentIndex] = "";
            return updatedPictures;
        });
        //Update local state
        dispatch(updateUserDataProperty({property: route, value: uploadedPictures}))
        setShowUploadPictureModal(false);
    }

    const Picture = (props: any)=>{
        const {pictureKey, index, isPlaceholder} = props;
        // console.log("Key: ", key);
        // console.log("isNan(Key): ", isNaN(key));
        return(
            <TouchableContainer
                onPress={()=>{
                    // console.log("Selected index: ", index);
                    setCurrentIndex(index)
                    setShowUploadPictureModal(true);
                }}
                key={ isPlaceholder ? Math.random() : index}
                style={{
                    borderStyle: 'dashed',
                    borderColor: COLORS.primaryRed,
                    borderRadius: isPlaceholder ? 1 : 0,
                    borderWidth: isPlaceholder? 2 : 0,
                    backgroundColor: isPlaceholder ? 'white' : 'transparent',
    
                    height: SCREEN_SIZE.width / 4, // change to width / 5 to make sure 5 items fit per row
                    width: SCREEN_SIZE.width / 5, // change to width / 5
                    margin: 5,
                    marginBottom: SCREEN_SIZE.width / 20,
                }}>
                <ImageBackground
                    // key={Date.now()}
                    //@ts-ignore
                    resizeMode={'cover'}
                    //@ts-ignore
                    style={{
                        flex: 1,
                        justifyContent: 'flex-end',
                    }}
                    //@ts-ignore
                    // imageStyle={this.props.imageStyle}
                    // source={require('../assets/alwayslocal-logo.png')}
                    source={isPlaceholder ? require('../assets/alwayslocal-logo.png') : `${S3_URL}/${pictureKey}`}
                    // imageRef={((imgBackground)=>{
                    //     imgBackground.queryCache(this.state.source.uri);
                    // })}
                >
                    <View
                        //@ts-ignore
                        style={{
                            height: 30,
                            width: 30,
                            borderRadius: 15,
                            backgroundColor: COLORS.primaryRed,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-end',
                            position: 'relative',
                            top: '6%',
                            left: '6%'
                        }}
                    >
                        <Ionicons
                            name={(Platform.OS === 'ios' ? 'ios-add' : 'md-add')}
                            size={15} 
                            color={'white'}
                            style={{alignSelf: 'center'}}
                        />
                    </View>
                </ImageBackground>
            </TouchableContainer>
        )
    }
    const renderUploadedPictures = () => {
        let pictureComponents = [];
        for (let index = 0; index < MAX_NUMBER_OF_PICTURES; index += 1) {
            const pictureKey = uploadedPictures[index];
            const isPlaceholder = isVarDefined(pictureKey) ? false : true;
            pictureComponents.push(
                <Picture
                    key={isPlaceholder ? Math.random() : pictureKey}
                    pictureKey={pictureKey}
                    index={index}
                    isPlaceholder={isPlaceholder}
                />
            );
        }
        return pictureComponents;
    }

    
    return(
        <View style={{flex: 1, backgroundColor: COLORS.primaryGray, borderRadius: 10}}>
            <UploadPictureModal
                showModal={showUploadPictureModal}
                setShowModal={setShowUploadPictureModal}
                onPictureUploaded={onPictureUploaded}
                picturePrefix={isAccount ? `${userData.uid}-account-picture` : `${userData.uid}-account-entertainment-picture`}
                // selectedImageKey={0}
                selectedImageKey={uploadedPictures[currentIndex]}
                onRemoveSelectedImage={onRemoveSelectedImage}
            />
            <ScrollView
                key={uploadedPictures.length}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                    backgroundColor: COLORS.primaryBgColor,
                    flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                {renderUploadedPictures()}
            </ScrollView>
            {/* <View key={uploadedPictures.length} style={{flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                {renderUploadedPictures()}
            </View> */}
        </View>
    )

}
  
export default AccountChoosePictures;