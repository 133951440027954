import Fire from "../Fire";
import catchErrorForSentry from "./catchSentryError";
const firebase = Fire.getFirebase();

function updateCurrentUserEmail(newEmail: string, onSuccess?: any, onFailure?: any): Promise<void> {
    const user = firebase.auth().currentUser;
  
    if(user) {
      return user.updateEmail(newEmail)
        .then((success) => {
          console.log('Email updated!');
          onSuccess(success);
        })
        .catch((error) => {
          catchErrorForSentry(error);
          onFailure(error);
        });
    } else {
      onFailure('No user signed in')
      throw new Error('No user is signed in.');
    }
}

export default updateCurrentUserEmail;