import React, {useState} from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import AlwaysLocalLogo from '../components/AlwaysLocalLogo';
import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import { COLORS, regexPatterns } from '../Constants';
import Fire from '../Fire';
import SignUpSheet from '../navigation/components/SignUpSheet';
import catchErrorForSentry from '../helpers/catchSentryError';
import TouchableBackButton from '../components/TouchableBackButton';
import { useDispatch } from 'react-redux';
import { setUserData } from '../store/slices/userData';
import getUserDataAsync from '../helpers/getUserDataAsync';
const firebase = Fire.getFirebase();

const WebLoginPage = (props: any)=>{
    const dispatch = useDispatch();
    const {navigation} = props;
    const [state, setState] = useState({
        email: "",
        // email: "tyfooods@gmail.com",
        emailError: 'Email must be valid',
        
        password: "",
        // password: "alwayslocal",
        passwordError: 'Password must be greater than 6 characters',
        
        usernameErrorFlag: false,
        nameFlag: false,
        emailErrorFlag: false,
        phoneNumberErrorFlag: false,
        passwordErrorFlag: false,
    })
    const [processing, setProcessing] = useState(false);
    const signUpSheetInputs = [
        {
            name: 'Email',
            errorMessage: state.emailError,
            errorName: 'emailErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;}),
            regex: regexPatterns.email,
            defaultValue: state.email || '',
        },
        {
            name: 'Password',
            errorMessage: state.passwordError,
            errorName: 'passwordErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.password = value; return prevState;}),
            regex: regexPatterns.password,
            defaultValue: state.password || '',
            props: {
                secureTextEntry: true
            },
        },
    ]

    const onLoginPress = (event: any)=> {
        setProcessing(true);
        event.preventDefault();
        const email = state.email.trim();
        const password = state.password;
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(function() {
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
            // ...
            // New sign-in will be persisted with session persistence.
            // console.log("email of login is: ", email);
            return (
              firebase.auth().signInWithEmailAndPassword(email, password)
              .then(async (user)=>{
    
                setProcessing(false);
                console.log("Sucessfully signed in user: ", user);
                const userData = await getUserDataAsync(user.user.uid);
                dispatch(setUserData(userData))
                navigation.push('WebBackEndPage');
                //Maybe set user data here globally for user later?
              })
              .catch((error)=>{
                console.log("Error: ", error);
                console.log("Error signing in user JSON: ", JSON.stringify(error)); 
                catchErrorForSentry(error);
                // Sentry.captureException("Error signing in user: ", error);
    
                if(error.code === 'auth/user-not-found'){
                  alert("User email not found!");
                }
                else if(error.code === 'auth/wrong-password'){
                  alert("Your password is incorrect");
                }
                else if(error.code === 'auth/invalid-email'){
                  alert("Your email is invalid");
                }
                else if(error === 'Error: Location provider is unavailable. Make sure that location services are enabled.'){
                  alert("Location provider is unavailable. Make sure that your location services are enabled.");
                }
                else{
                  alert("Strange error while trying to sign you in occurred - Please email ty@tyfoodsforthought.com for assistance");
                }
                console.log(`Authentication failed with this error: ${error}`)
                setProcessing(false);
              })
            )
          })
          .catch(function(error) {
            // Handle Errors here.
            console.log("Error with set persistance - code: ", JSON.stringify(error.code));
            console.log("Error with set persistance - message: ", JSON.stringify(error.message));
            // Sentry.captureException("Error Signing in with email/password");
          });
      }
    return(
        <View style={{...styles.container}}>
            <Card style={{
                ...styles.signUpForm,
                justifyContent: 'center',
                alignItems: 'center', 
                padding: 10,
            }}>
                <TouchableBackButton
                      onPress={()=>props.navigation.pop()}
                      containerStyle={{
                          margin: 20,
                          position: 'absolute',
                          top: 0,
                          left: 0,
                      }}
                      size={30}
                  />
                <AlwaysLocalLogo style={{top:0}}/>
                {
                    processing
                    ?
                    <ActivityIndicator color={COLORS.primaryRed} size="large"/>
                    :
                    <View style={{width: '80%'}}>
                        <SignUpSheet inputs={signUpSheetInputs} state={state} setState={setState} />
                        <CustomButton
                            onPress={(event: React.MouseEvent<HTMLButtonElement>)=>onLoginPress(event)}
                            style={{
                                ...styles.accTypeBtn,
                                alignSelf: 'center',
                                marginTop: 30
                            }}
                            textStyle={styles.accTypeBtnTxt}
                            title={"Login"}
                        />
                    </View>
                }
            </Card>
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#F8FAFC',
        alignItems: 'center',
        justifyContent: 'center',
    },
    signUpForm: {
        // flex: 1,
        height: '80%',
        width:'80%',
    },
    accTypeBtn:{
        borderRadius: 10,
        backgroundColor: '#DD222E',
        width: 150,
        height: 70,
        justifyContent: 'center',
    },
    accTypeBtnTxt:{
        fontSize: 20,
        alignSelf: 'center',
        color: 'white',
        fontWeight: "500",
    },
})

export default WebLoginPage