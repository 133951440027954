import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface UserData {
//   accountType: 'Brand' | 'Account' | 'User' | 'Staff' | 'Entertainment',
//   accountType: string,
//   uid: string,
//   username: string,
//   email: string,
//   bio: string,
//   coverPhotoKey: string,
//   profilePictureKey: string,
//   uidsFollowingUser: string[],
//   uidsUserFollows: string[],
// }

const initialState = {
  accountType: "",
  uid: "",
  username: "",
  displayName: "",
  email: "",
  bio: "",
  website: "",
  coverPhotoKey: "",
  profilePictureKey: "",
  uidsFollowingUser: [],
  uidsUserFollows: [],
  pictureKeys: {},
  entertainmentPictureKeys: {},
  address: "",
  facebookUrl: "",
  instagramUrl: "",
  coords: {},
  dailySpecials: [],
  weeklySpecials: [],
  monthlySpecials: [],
  dealOne: {},
  dealTwo: {},
  monthlySpecialsImageKey: "",
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction) => {
      return action.payload;
    },
    updateUserDataProperty: (state: any, action: PayloadAction<{ property: string; value: any }>) => {
      const { property, value } = action.payload;
      state[property] = value;
    },
  },
});

export const { setUserData, updateUserDataProperty } = userDataSlice.actions;
export default userDataSlice.reducer;