import { Image } from "react-native"

export default (props: any)=>{
    return(
        <Image
            resizeMode='contain'
            style={{
                // position: 'absolute',
                // bottom: 0,
                width: 200,
                height: 75,
                alignSelf: 'center',
                ...props.style
            }}
            source={require('../assets/alwayslocal-logo.png')}
        />
    )
}