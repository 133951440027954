import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import { S3_URL } from './../Constants/index';
import shorthash from 'shorthash';
import catchErrorForSentry from './catchSentryError';


const deleteImageFromCache = (oldImageKey: string)=>{
    if(Platform.OS === 'web') return;
        
    let name = shorthash.unique(`${S3_URL}${oldImageKey}`);
    const path = `${FileSystem.cacheDirectory}${name}`;
    FileSystem.deleteAsync(path)
    .then((response)=>{
        console.log("Successfully delete old image from cache: ", response)
    })
    .catch((error)=>{
        console.log("Error: ", error);
        console.log("Error deleting image from cache: ", JSON.stringify(error)); 
        catchErrorForSentry(error);
    })
    
}

export default deleteImageFromCache