import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Text, Modal, TouchableWithoutFeedback, Image, ActivityIndicator } from 'react-native';
import { S3_URL, SCREEN_SIZE, errorMessages, regexPatterns } from '../Constants';
// import { cloudFrontUrl, SCREEN_SIZE } from '../Constants';
import getCameraRollPermissionAsync from '../helpers/getCameraRollPermissionAsync';
import * as ImagePicker from 'expo-image-picker';
import CustomButton from './CustomButton';
import { Platform } from 'react-native';
// import uploadToStorage from '../helpers/uploadToStorage';
// import i18n from 'i18n-js';
import { COLORS } from '../Constants';
// import { connect } from 'react-redux';
// import Fire from '../Fire';
// import scaleFontSize from '../utils/scaleFontSize';
import { scale } from 'react-native-size-matters/extend';
import uploadToStorage from '../helpers/uploadToStorage';
import isMobile from '../helpers/isMobile';
import catchErrorForSentry from '../helpers/catchSentryError';
import ValidateInput from './ValidateInput';
import Card from './Card';
import reauthCurrentUser from '../helpers/reauthCurrentUser';
import updateCurrentUserPhonenumber from '../helpers/updateCurrentUserPhonenumber';
import ConfirmSMSBox from './ConfirmSMSBox';
import { FirebaseRecaptcha, FirebaseRecaptchaVerifier, FirebaseRecaptchaVerifierModal } from 'expo-firebase-recaptcha';
import Fire from '../Fire';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
const firebase = Fire.getFirebase()


const VerifyPhoneNumberModal = (props: any)=>{

    const {
        onCloseModal,
        setShowModal,
        showModal,
        phoneNumber,
        onVerifyPhoneNumberSuccess
    } = props

    const userData = useSelector((state: RootState) => state.userData);
    const recaptchaVerifier = useRef(null);
    const [state, setState] = useState({phoneNumber: "", phoneNumberErrorFlag: false, email: "", password: "", emailErrorFlag: false, passwordErrorFlag: false})
    const [smsCode, setSmsCode] = useState("");
    const [verifyingRecaptcha, setVerifyingRecaptcha] = useState(false);
    const [confirmingSMSCode, setConfirmingSMSCode] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [verificationId, setVerificationId] = useState(false);

    useEffect(()=>{
        if(phoneNumber && showModal){
            setVerifyingRecaptcha(true);
            sendVerification(phoneNumber);
        }
    }, [showModal])

    const sendVerification = (phoneNumber: string) => {
        // return;
        const phoneProvider = new firebase.auth.PhoneAuthProvider();
        // console.log("Sending SMS");
        phoneProvider
            //@ts-ignore
            .verifyPhoneNumber(phoneNumber, recaptchaVerifier.current)
            //@ts-ignore
            .then((token: any)=>{
                setVerificationId(token);
                setConfirmingSMSCode(true);
                setVerifyingRecaptcha(false);
            })
            .catch((error)=>{
    
            if(error.code === 'ERR_FIREBASE_RECAPTCHA_CANCEL'){
              alert('You must complete the captcha to continue!');
            }
            else if(error.code === 'auth/invalid-phone-number'){
              alert("Your phone number has an invalid format")
            }
            else if(error.code === 'auth/captcha-check-failed'){
              alert("There was an issue processing your captcha, please try again!")
            }
            else{
              alert("There was an error verifying your phone number. Try again, or please email support@alwayslocal.net for help!")
              console.log("Error verifying captcha: ", error);
              console.log("Error verifying phone number: ", JSON.stringify(error)); 
              catchErrorForSentry(error);
            }
            setVerifyingRecaptcha(false);
    
            // https://firebase.google.com/docs/reference/js/firebase.auth.PhoneAuthProvider
            // auth/missing-phone-number
            // Thrown if the phone number is missing.
            // auth/quota-exceeded
            // Thrown if the SMS quota for the Firebase project has been exceeded.
            // auth/user-disabled
            // Thrown if the user corresponding to the given phone number has been disabled.
            // auth/maximum-second-factor-count-exceeded
            // Thrown if The maximum allowed number of second factors on a user has been exceeded.
            // auth/second-factor-already-in-use
            // Thrown if the second factor is already enrolled on this account.
            // auth/unsupported-first-factor
            // Thrown if the first factor being used to sign in is not supported.
            // auth/unverified-email
            // Thrown if the email of the account is not verified.
         
          })
    };
    
    const renderContent = ()=>{
        if(confirmingSMSCode) return (
            <ConfirmSMSBox
                containerStyle={{
                    height: '100%', width: '100%', padding: 0,
                    // borderColor: 'green', borderWidth: 1
                }}
                phoneNumber={state.phoneNumber}
                confirmSMSCode={confirmSMSCode}
                confirmingSMSCode={confirmingSMSCode}
                sendVerification={sendVerification}
                smsCode={smsCode}
                setSmsCode={setSmsCode}
            />
        )
        if(processing) return <ActivityIndicator color={COLORS.primaryRed} size="large"/>
    }

    const onPhoneNumberUpdateSuccess = ()=>{
        //Updating database
        firebase.database().ref(`phoneNumbers/${userData.uid}`).set(phoneNumber);
        alert("Successfully updated phone number");
        setShowModal(false);
    }

    const onPhoneNumberUpdateFailure = (error: any)=>{
        catchErrorForSentry(error);
        alert("Failed to update your phone number! Please try again")
    }

    const confirmSMSCode = (smsCode: number | string) => {
        setProcessing(true);
        const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
            //@ts-ignore
            verificationId,
            smsCode
        );
        updateCurrentUserPhonenumber(phoneCredential, onPhoneNumberUpdateSuccess, onPhoneNumberUpdateFailure)
  
    }

    return(
        <Modal
            style={{zIndex: 9999}}
            animationType="fade"
            transparent={true}
            visible={showModal}
            onRequestClose={()=>{
                console.log("Closing modal");
            }}
        >
        <View style={{
            // zIndex: 9999,
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, .6)',
            alignContent: 'center',
            justifyContent: 'center',
            height: SCREEN_SIZE.height,
            width: SCREEN_SIZE.width,
            }}>
                <TouchableWithoutFeedback
                    onPress={()=>{
                        if(onCloseModal) onCloseModal();
                        setShowModal(false)
                    }}
                >
                    <View style={{
                        height: '55%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                    }}>

                    </View>
                </TouchableWithoutFeedback>
                <View style={Platform.OS === 'web' ? {...styles.webModalCard, width: isMobile() ? '90%' : '50%'} : styles.mobileModalCard}>

                

                        {
                            verifyingRecaptcha &&
                            <View style={{justifyContent: 'center', alignItems: 'center', marginBottom: 20,}}>
                                <Text style={{ fontSize: 20, fontWeight: '400', marginBottom: 5, textAlign: 'center', alignSelf: 'center'}}>
                                        Please verify you are human with the following captcha
                                </Text>
                                <Text style={{ fontSize: 15, textAlign: 'center', alignSelf: 'center'}}>
                                        You will receive an sms code upon success
                                </Text>
                            </View>
                        }
                        {!confirmingSMSCode && <FirebaseRecaptchaVerifierModal
                            ref={recaptchaVerifier}
                            firebaseConfig={firebase.app().options}
                        />}
                        {renderContent()}
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    btnTextStyle:{
        fontSize: 25,
        color: '#fff',
    },
    uploadPictureBtn:{
        width: '50%',
        borderRadius: 10,
        padding: 10,
        alignSelf: 'center',
    },
    mobileModalCard: {
        borderTopLeftRadius: 50,
        borderTopRightRadius: 50,
        backgroundColor: '#fff',
        height: '45%',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    webModalCard: {
        borderRadius: 50,
        backgroundColor: '#fff',
        height: '65%',
        position:'relative',
        bottom: 'default',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
})


  
export default VerifyPhoneNumberModal