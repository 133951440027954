import { SERVER_URL } from './../Constants/index';
import catchErrorForSentry from './catchSentryError';
function isAccountInformationTaken(phoneNumber: string, email: string, username:string) {
    let status;
    return fetch(`${SERVER_URL}/isAccountInformationTaken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({phoneNumber, email, username}),
      })
      .then(async (res) => {
          status = res.status;
          let response = await res.text();
          return response
      })
      .catch(error => {
          alert("Error checking if account information is taken - Please try again!")
          console.log("Error checking if account information is taken: ", error);
          console.log("Error checking if account information is taken ", JSON.stringify(error)); 
          catchErrorForSentry(error);
          return null;
      });
}

export default isAccountInformationTaken