export default {
    en: {

        NumberInUse: "This number is already in use!",
    },
    //Hebrew Israel
    'he-IL': {

    },
    //French France
    'fr-FR':{

    }

}
