import { Dimensions, Platform } from "react-native";
const isMobile = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }

  const userAgent = navigator.userAgent || navigator.vendor;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};
const DEBUG = false;

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCF0c6sUuW6ovX9RkVXX_R7JRJB2QeqNYA'

export const SCREEN_SIZE: any = (()=>Platform.OS === 'web' ? Dimensions.get('window') : Dimensions.get('screen'))()

export const mediaUrl = ""

export const S3_URL = 'https://alwayslocal-pictures.s3.us-east-2.amazonaws.com'

export const SERVER_URL = DEBUG ? (isMobile() ? 'http://192.168.56.1:3001'  : 'http://localhost:3001') : 'https://alwayslocal-server.herokuapp.com'

export const pictureBucket = 'alwayslocal-pictures';

export const videoBucket = 'alwayslocal-videos';

//This is for scaling components etc across various devices
export const SCALE_VALUE = 2;

export const errorMessages = {
  usernameError: 'Usernames can only contain up to 30 of these characters: a-z, 0-9, _ and . (only in the middle)',
  firstNameError: 'First names must start with a capital letter and can only contain up to 30 characters',
  lastNameError: 'Last names must start with a capital letter and can only contain up to 30 characters',
  phoneNumberError: 'Phone numbers must be of the form "+ (country code) (area code) (number)" ',
  emailError: 'Email must be valid',
  passwordError: 'Password must be greater than 6 characters',
  websiteError: "There is an error with your url format! - Must have https://, or https:// at the beginning"
}

export const regexPatterns= {
    name: /^[A-Z][a-z]+$/,
    username: /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/,
    email: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    phoneNumber: /^\+(?:[0-9]●?){6,14}[0-9]$/,
    password: /^.{6,}$/,
    url: new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)?(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$', 'i')
}

export const COLORS ={
  primaryGray: '#F8FAFC',
  slateGray: '#232323',
  // primaryGray: '#3B4044',
  // light: {
  //   text: '#000',
  //   background: '#fff',
  //   tint: tintColorLight,
  //   tabIconDefault: '#ccc',
  //   tabIconSelected: tintColorLight,
  // },
  // dark: {
  //   text: '#fff',
  //   background: '#000',
  //   tint: tintColorDark,
  //   tabIconDefault: '#ccc',
  //   tabIconSelected: tintColorDark,
  // },
  inactiveTabColor: '#D0CECE',
  primaryTextColor: '#011C42',
  primaryBgColor: '#F5F5F5',
  primaryOrange: '#FF9026',

  //This is from Flow Arts Meetup
  primaryBlue: '#168090',
  primaryRed: '#B82025',
  primaryPurple: '#901680',
  // primaryGray: '#f2f2f2',
  grayText: '#617D8A',
}