import { View } from "react-native";
// import MiddleTabSeat from "../../components/MiddleTabSeat";
import { SCREEN_SIZE } from "../../Constants";
import CreateStickerButton from "./CreateStickerButton";
import TabButton from "./TabButton";

const tabBarHeight = SCREEN_SIZE.height * .070;

function TabBar({ state, descriptors, navigation }: any) {

   let routesObj: any = {};
   state.routes.forEach((route: any)=>{routesObj[route.name]=route})
   
   const onCreateStickerPress = (route: any, isFocused: Boolean) => {
       const event = navigation.emit({
         type: 'tabPress',
         target: route.key,
       });
 
       if (!isFocused && !event.defaultPrevented) {
         navigation.push(route.name);
       }
   };

   const onPress = (route: any, isFocused: Boolean) => {
       const event = navigation.emit({
         type: 'tabPress',
         target: route.key,
       });
 
       if (!isFocused && !event.defaultPrevented) {
         navigation.navigate(route.name);
       }
   };

   const onLongPress = (route: any) => {
       navigation.emit({
         type: 'tabLongPress',
         target: route.key,
       });
   };

   //TODO: Make this shit transparent wtf
   //Only becomes transparent with position absolute.
   //This would make styling every screen hacky and lame.
   return (
     <View style={{
    //    position: 'absolute',
       flexDirection: 'row',
       width: SCREEN_SIZE.width,
       justifyContent: 'space-around',
       backgroundColor: 'transparent',
    //    height: SCREEN_SIZE.height * .07,
    //    borderWidth: 2,
    //    borderColor: 'red'
   }}>
       
       <TabButton
           onLongPress={()=>onLongPress(routesObj['Home'])}
           onPress={()=>onPress(routesObj['Home'], state.index === 0)}
           focused={state.index === 0}
           title="Home" icon={"home"} />
       <View style={{width: '33%'}}>
           <CreateStickerButton
               onLongPress={()=>onLongPress(routesObj['CreateSticker'])}
               onPress={()=>onCreateStickerPress(routesObj['CreateSticker'], state.index === 1)}
               tabBarHeight={tabBarHeight}
           />
           {/* <MiddleTabSeat tabBarHeight={tabBarHeight} /> */}
       </View>
       <TabButton
           onLongPress={()=>onLongPress(routesObj['Settings'])}
           onPress={()=>onPress(routesObj['Settings'], state.index === 2)}
           focused={state.index === 2}
           title="Settings" icon={"settings"} />
     </View>
   );
}

export default TabBar;