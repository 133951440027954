import { StyleSheet, View, Text, Image, ActivityIndicator, TextInput, ScrollView } from "react-native";
import { COLORS } from "../Constants";
import Card from "./Card";
import { memo } from 'react';
import CustomButton from "./CustomButton";

const ConfirmSMSBox = memo((props: any) => {
    const { smsCode, setSmsCode, phoneNumber, confirmSMSCode, confirmingSMSCode, sendVerification } = props
    // // Ask the user for the verification code they received
    // //TODO: If NULL, then this shouldn't be allowed to go forward, reprompt.
    // const verificationCode: any = prompt('Enter the verification code you received:');
    // // Link the verified phone number to the user
    // await Fire.linkPhoneNumberToUser(confirmationResult, verificationCode, state.phoneNumber);
    return(
        <Card style={{alignItems: 'center', justifyContent: 'center', width: '50%', height: '50%', ...props.containerStyle}}>
            {confirmingSMSCode === null && <Text>The SMS code used is invalid or expired, please try again.</Text>}
            <View style={{
                width: '40%',
                backgroundColor: COLORS.primaryBgColor,
                padding: 5,
                borderRadius: 10,
                borderColor: '#000',
                borderWidth: 2,
                marginBottom: 40,
            }}>
                <TextInput
                    style={{width: '100%', flex: 1}}
                    value={smsCode}
                    onChangeText={setSmsCode}
                    textAlign='center'
                    keyboardType="numeric"
                    placeholder="Code from SMS"
                />
            </View>
            <CustomButton
                onPress={
                    ()=>{
                        if(!smsCode){
                            alert("You must enter your SMS Code!");
                            return;
                        }
                        confirmSMSCode(smsCode);
                    }
                
                }
                // onPress={()=>console.log("Confirming SMS code: ", smsCode)}
                title="Confirm SMS code"
                textStyle={{fontSize: 15, color: '#fff'}}
                style={{width: '40%', borderRadius: 10, padding: 5}}
            />
    
            
            <CustomButton
                onPress={
                    ()=>{
                        sendVerification(phoneNumber);
                    }
                
                }
                title="Re-send code"
                textStyle={{fontSize: 15, color: '#fff'}}
                style={{
                    width: '40%',
                    borderRadius: 10,
                    padding: 5,
                    marginTop: 10,
                    marginBottom: 10,
                    backgroundColor: COLORS.primaryRed,
                    alignSelf: 'center',
                }}
            />
        </Card>
    )
    
})

export default ConfirmSMSBox