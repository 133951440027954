import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer  } from '@react-navigation/native';
import * as React from 'react';
import { useState, useEffect } from 'react';
import EmailCTAPage from '../webpages/EmailCTAPage';
import HomeHeader from './components/HomeHeader';
import TabBar from './components/TabBar';
import WebSignUpPage from '../webpages/WebSignUpPage';
import Fire from '../Fire';
import WebBackEndPage from '../webpages/WebBackEndPage';
import WebLoginPage from '../webpages/WebLoginPage';
import {
  SafeAreaProvider,
} from 'react-native-safe-area-context';
import { useSelector, useDispatch } from 'react-redux';
import { setUserData } from '../store/slices/userData';
import { RootState } from '../store';
import getUserDataAsync from '../helpers/getUserDataAsync';
import AccountProfile from '../screens/AccountProfile';
import { ActivityIndicator, View } from 'react-native';
import { COLORS } from '../Constants';
const firebase = Fire.getFirebase();
/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
 const Stack = createNativeStackNavigator();

 function RootNavigator({ isLoggedIn }: any) {
    return (
      <Stack.Navigator initialRouteName='Home'>
        <Stack.Screen initialParams={{ isLoggedIn }} name="Home" component={isLoggedIn ? WebBackEndPage : EmailCTAPage}
        // <Stack.Screen initialParams={{ isLoggedIn }} name="Home" component={isLoggedIn ? AccountProfile : AccountProfile}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(<HomeHeader route={route} navigation={navigation} isLoggedIn={isLoggedIn}/>)
        })}/>
        <Stack.Screen name="WebBackEndPage" component={WebBackEndPage}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(<HomeHeader route={route} navigation={navigation} isLoggedIn={isLoggedIn}/>)
        })}/>
        <Stack.Screen name="WebLoginPage" component={WebLoginPage}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(<HomeHeader route={route} navigation={navigation} isLoggedIn={isLoggedIn}/>)
        })}/>
        <Stack.Screen name="WebSignUpPage" component={WebSignUpPage} options={{ headerShown: false }} />
      </Stack.Navigator>
    );
 }

const BottomTabNavigator = (props: any)=>{

  const BlankComponent = ()=><></>

  return(
    <Tab.Navigator tabBar={(props) => <TabBar {...props} />}>
          <Tab.Screen name="Home" component={EmailCTAPage} 
            options={{
                header: () => <HomeHeader/>
            }}/>
          {/* This is being overriden in stack navigator to avoid tab bar*/}
          {/*
            Alternative to this method is here:
            https://stackoverflow.com/questions/68900300/react-navigation-opening-a-modal-from-the-tab-bar
            Might be equivalent to what I'm doing here - I.E. Not any faster.
          */}
          {/* <Tab.Screen name="CreateSticker" component={BlankComponent} /> */}
          {/* <Tab.Screen name="Settings" component={TabTwoScreen} /> */}
    </Tab.Navigator>
  )
}

//TODO: What is the linking parameter?
//I think it's important for react-native-web
//Be sure to learn more via LinkingConfigurations.tsx
const Tab = createBottomTabNavigator();
export default function App() {

  const [isLoggedIn, setIsLoggedIn] = useState<any>(false);
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.userData);
  const [processing, setProcessing] = useState<any>(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const data = await getUserDataAsync(user.uid)
        dispatch(setUserData(data));
        setProcessing(false);
        setIsLoggedIn(true);
      } else {
        setProcessing(false);
        setIsLoggedIn(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

    return (
      // <SafeAreaProvider>
        <NavigationContainer>
          {
            processing
            ?
            <View style={{flex: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
              <ActivityIndicator color={COLORS.primaryRed} size="large" />
            </View>
            :
            <RootNavigator isLoggedIn={isLoggedIn}/>
          }
        </NavigationContainer>
      // </SafeAreaProvider>
    );
}
