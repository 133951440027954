import { COLORS, SCREEN_SIZE } from '../Constants/';
// import Colors from '../Constants/Colors';
// COLORS
import { LinearGradient } from 'expo-linear-gradient';
import { Modal, Platform, Keyboard, View, StyleSheet, ScrollView, Text, TouchableOpacity } from 'react-native';
import React, { useState, useEffect } from 'react';
import Card from '../components/Card';
// import { useFonts } from '@use-expo/font';
// import { useHeaderHeight } from 'react-navigation-stack';
import Constants from 'expo-constants';

const DEFAULT_MODAL_SIZE = SCREEN_SIZE.height * .90;

const CustomModal = (props: any)=>{
    const {
        visibilityFlag,
        visibilityControlFunc,
        onBack = null,
        title = "",
        onSubmit,
        confirmText,
        showSubmit = true,
        scroll = true,
        showTitle = true,
        responsive = true,
        // keyboardShouldPersistTaps = 'always',
        // keyboardDismissMode = null
    } = props;
    
    
    // let [fontsLoaded] = useFonts({
    //     'Nadia-Sofia': require('../assets/fonts/Nadia_Sofia.ttf'),
    // });
    // const Header = useHeaderHeight();

    //TODO: Fix these later!
    const fontsLoaded = true;
    const Header = 10;

    const [ modalSize, setModalSize ] = useState<number>(DEFAULT_MODAL_SIZE);

    useEffect(()=>{
        //Setting up event listeners - derp
        Keyboard.addListener(
            'keyboardDidHide',
             handleKeyboardHidden,
          );
  
          Keyboard.addListener(
            'keyboardDidShow',
            handleKeyboardShown,
          );

          return () =>{
            Keyboard.removeAllListeners("keyboardDidShow");
            Keyboard.removeAllListeners("keyboardDidHide");
            // Keyboard.removeListener("keyboardDidShow", handleKeyboardShown);
            // Keyboard.removeListener("keyboardDidHide", handleKeyboardHidden);
          }
    }, [])



    const handleKeyboardHidden = ()=>{
        setModalSize(DEFAULT_MODAL_SIZE);
    }

    //TODO: Give this a proper type
    const handleKeyboardShown = (e: any)=>{
        if(e){
            setModalSize( (DEFAULT_MODAL_SIZE - e.endCoordinates.height - Header));
            // setModalSize( (DEFAULT_MODAL_SIZE + Header - e.endCoordinates.height));
        }
    }


    const renderTitle = ()=>{
        if(fontsLoaded && showTitle){
            return(
                <LinearGradient
                    colors={[COLORS.primaryRed, COLORS.primaryGray]}
                    style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                    start={{x: 0, y: 0}}
                    end={{x: 1, y: 0}}
                >
                    <Text style={{
                        textAlign: 'center',
                        fontSize: 15,
                        borderBottomWidth: 2,
                        borderBottomColor: '#000',
                        padding: 5,
                        color: '#fff',
                        fontFamily: 'Nadia-Sofia',
                    }}>
                        {title}
                    </Text>
                </LinearGradient>
            )
        }
    }

    const renderButtons = ()=>{
        return(
            <View style={{
                flexDirection: 'row',
                borderTopWidth: 2,
                borderTopColor: '#000',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
            }}>
                {( (fontsLoaded && showSubmit) &&
                    <LinearGradient
                        colors={[COLORS.primaryGray, COLORS.primaryRed]}
                        style={{borderBottomLeftRadius: 10, flex: 1}}
                        start={{x: 0, y: 0}}
                        end={{x: 1, y: 0}}
                    >
                        <TouchableOpacity
                            onPress={async ()=>{
                                onSubmit();
                            }}>
                            <Text style={{
                                padding: 5,
                                color: '#fff',
                                fontSize: 15,
                                fontFamily: 'Nadia-Sofia',
                                textAlign: 'center',
                            }}>
                                {(confirmText ? confirmText : 'Submit')}
                            </Text>
                        </TouchableOpacity>
                    </LinearGradient>
                )}
                {(fontsLoaded &&
                    <LinearGradient
                        colors={[COLORS.primaryRed, COLORS.primaryGray]}
                        style={{
                            borderBottomRightRadius: 10,
                            borderBottomLeftRadius: (showSubmit ? 0 : 10),
                            flex: 1
                        }}
                        start={{x: 0, y: 0}}
                        end={{x: 1, y: 0}}
                    >
                        <TouchableOpacity
                            onPress={()=>{
                                visibilityControlFunc(false);
                                if(onBack){
                                    onBack();
                                }
                            }}>
                            <Text style={{
                                padding: 5,
                                color: '#fff',
                                fontSize: 15,
                                fontFamily: 'Nadia-Sofia',
                                textAlign: 'center',
                            }}>
                                Back
                            </Text>
                        </TouchableOpacity>
                    </LinearGradient>
                )}
            </View>
        )
    }

    return(
        <Modal
            // presentationStyle={'overFullScreen'}
            animationType="fade"
            transparent={true}
            visible={visibilityFlag}
            onRequestClose={()=>{
                // console.log("Closing modal");
            }}
        >
            <View style={{
                backgroundColor: 'rgba(0,0,0,.9)',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 0,
            }}>
                <Card
                    style={{
                        backgroundColor: COLORS.primaryBgColor,
                        marginTop: (responsive ? 0 : Constants.statusBarHeight * 2),
                        height: (responsive ? modalSize : SCREEN_SIZE.height),
                        // height: (modalSize),
                        position: (Platform.OS === 'ios' ? 'absolute' : 'relative'),
                        top: (Platform.OS === 'ios' ? Header/2 : 0),
                        width: SCREEN_SIZE.width *.9,
                        zIndex: 1,
                        ...props.cardStyle
                        
                }}>
                    {renderTitle()}
  
                        <ScrollView
                            contentContainerStyle={{flex: 1, ...props.containerStyle}}
                            keyboardShouldPersistTaps='always'
                            nestedScrollEnabled={true}
                            scrollEnabled={scroll}
                        >
                            {props.children}
                        </ScrollView>
 
                    {renderButtons()}
                </Card>
            </View>
        </Modal>
    )
}

export default CustomModal