import Fire from '../Fire';
const firebase = Fire.getFirebase()

const getUserDataAsync = async (uid: string)=>{
    let publicData = await firebase.database().ref(`publicUserData/${uid}`).once('value');
    let privateData = await firebase.database().ref(`privateUserData/${uid}`).once('value');
    let userData = {
      ...publicData.val(),
      ...privateData.val()
    }
    return userData
}

export default getUserDataAsync