import { Platform, Pressable, StyleSheet, TouchableNativeFeedback } from "react-native"
const TouchableContainer = (props: any)=>{
    if(Platform.OS === 'web'){
        return(
            <Pressable style={{...props.style}} onPress={props.onPress}>
                {props.children}
            </Pressable>
        )
    }
    return(
        <TouchableNativeFeedback style={{...props.style}} onPress={props.onPress} >
            {props.children}
        </TouchableNativeFeedback>
    )
}

export default TouchableContainer