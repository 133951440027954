import React, { useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import Card from '../components/Card';
import UploadPictureModal from '../components/UploadPictureModal';
import AccountChoosePictures from '../components/AccountChoosePictures';
import SocialBanner from '../components/SocialBanner';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserDataProperty } from '../store/slices/userData';
import { RootState } from '../store';
import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter';
import Fire from '../Fire';
import { mediaUrl, S3_URL } from '../Constants';
import confirmDecision from '../helpers/confirmDecision';
import deleteFromStorage from '../helpers/deleteFromStorage';
import deleteImageFromCache from '../helpers/deleteImageFromCache';
import AccountProfile from '../screens/AccountProfile';
const firebase = Fire.getFirebase();

const WebBackEndPage = (props: any)=>{
    // const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
    
    // const dispatch = useDispatch();
    // const userData = useSelector((state: RootState) => state.userData);
    // const [modalType, setModalType] = useState<"cover-photo"|"profile-picture"|"">("")
    // const [selectedImage, setSelectedImage] = useState<string>("");
    // const [imageKey, setImageKey] = useState<string>("")
    // const [profilePictureUrl, setProfilePictureUrl] = useState(`${mediaUrl}${userData.profilePictureKey}`);
    // const [coverPhotoUrl, setCoverPhotoUrl] = useState(`${mediaUrl}${userData.coverPhotoKey}`);
    

    // const onRemoveSelectedImage = async ()=>{
    //     let imageTypeName = (modalType === 'cover-photo' ? 'cover photo' : 'profile picture');
    //     let keyProperty = (modalType === 'cover-photo' ? 'coverPhotoKey' : 'profilePictureKey');

    //     let response = await confirmDecision(
    //         `Removing ${imageTypeName}`,
    //         `Are you sure you would like to remove your ${imageTypeName}`
    //     );
            
    //     if(!response) return;
    //     updateLocalPhotoBasedOnModalType(modalType, '');

    //     // Delete old photo from cache
    //     //@ts-ignore
    //     deleteImageFromCache(userData[`${keyProperty}`]);

    //     // deletePhotoFromStorageIfNotUsedInDating();
    //     //@ts-ignore
    //     let pictureKey = userData[`${keyProperty}`];
    //     deleteFromStorage(pictureKey, "image")

    //     //update database key
    //     updateDatabaseKeyBasedOnModalType(modalType, '');
    //     //Update globalState
    //     updateGlobalStateImageKey('');

    //     setShowUploadPictureModal(false);
    // }

    // const onPictureUploaded = (imageKey: string)=>{
    //     //updateFrontEnd
    //     updateLocalPhotoBasedOnModalType(modalType, imageKey);

    //     //update database key
    //     updateDatabaseKeyBasedOnModalType(modalType, imageKey);

    //     //Update globalState
    //     updateGlobalStateImageKey(imageKey);

    //     setShowUploadPictureModal(false);
    // }

    // const updateLocalPhotoBasedOnModalType = (modalType: string, imageKey: string)=>{
    //     let url = imageKey ? `${mediaUrl}${imageKey}` : '';
    //     //Switch local photo accordingly - Updates front end
    //     (modalType === 'cover-photo' ? setCoverPhotoUrl(url) : setProfilePictureUrl(url));
    // }

    // const updateDatabaseKeyBasedOnModalType = (modalType: string, newImageKey: string)=>{
    //     let property = (modalType === 'cover-photo' ? 'coverPhotoKey' : 'profilePictureKey');
    //     firebase.database().ref('publicUserData/' + userData.uid + `/${property}`).set(newImageKey);
    // }


    // const updateGlobalStateImageKey = async (newImageKey: string)=>{
    //     let modalTypeProperty = modalType === 'cover-photo' ? 'coverPhotoKey' : 'profilePictureKey';
    //     dispatch(updateUserDataProperty({property: modalTypeProperty, value: newImageKey}))
    // }

    return(
        <View style={styles.container}>
            {/* <UploadPictureModal
                showModal={showUploadPictureModal}
                setShowModal={setShowUploadPictureModal}
                onPictureUploaded={onPictureUploaded}
                picturePrefix={`${userData.username}-${modalType}`}
                selectedImageKey={selectedImage}
                onRemoveSelectedImage={onRemoveSelectedImage}
            /> */}
            <Card style={{
                ...styles.signUpForm,
                justifyContent: 'center',
                alignItems: 'center', 
                padding: 10,
            }}>
                <View style={{
                    flex: 1,
                    // borderColor: 'red',
                    // borderWidth: 3,
                    justifyContent: 'space-around'
                }}>
                    <View style={{}}>
                        <Text style={{fontSize: 25, textAlign: 'center', fontWeight: '500'}}>This is what your profile will look like.</Text>
                        <Text style={{fontSize: 13, textAlign: 'center'}}>Tap each item below to change it</Text>
                    </View>
                    <Card style={{width: '100%', height: '85%', alignSelf: 'flex-end'}}>
                        <AccountProfile/>
                    </Card>
                </View>
            </Card>
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        // borderWidth: 1,
        // borderColor: 'blue',
        // borderColor: 'red',
        // borderWidth: 3,

        flex: 1,
        backgroundColor: '#F8FAFC',
        alignItems: 'center',
        justifyContent: 'center',
    },
    signUpForm: {
        // flex: 1,
        height: '90%',
        width:'90%',
    }

})

export default WebBackEndPage