import React from 'react';
import { View, Text, StyleSheet, TouchableNativeFeedback, Platform, TouchableWithoutFeedback, Button, Pressable } from 'react-native';

const CustomButton = (props: any) => {

    if(Platform.OS === 'android'){
        return(
            <TouchableNativeFeedback onPress={props.onPress} >
                <View style={{...styles.mainButton, ...props.style}}>
                    <Text style={{...props.textStyle, ...styles.text}}>
                        {props.title}
                    </Text>
                </View>
            </TouchableNativeFeedback>
        )
    }
    if(Platform.OS === 'web'){
        return(
            <Pressable style={{...styles.mainButton, ...props.style}} onPress={props.onPress}>
                <Text style={{...props.textStyle, ...styles.text}}>
                    {props.title}
                </Text>
            </Pressable>
        )
    }
    return(
        <TouchableWithoutFeedback onPress={props.onPress} >
            <View style={{...styles.mainButton, ...props.style}}>
                <Text style={{...props.textStyle, ...styles.text}}>
                    {props.title}
                </Text>
            </View>
        </TouchableWithoutFeedback>
    )
}


const styles = StyleSheet.create({
    mainButton: {
        width: '100%',
        // borderRadius: 20,
        // overflow: 'hidden',
        color: '#fff',
        backgroundColor: '#000',
    },
    text: {
        textAlign: 'center'
    }
});

export default CustomButton;