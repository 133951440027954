import * as Location from 'expo-location';
import { GOOGLE_MAPS_API_KEY } from '../Constants';
Location.setGoogleApiKey(GOOGLE_MAPS_API_KEY);
const getLatLngFromAddress = async (address: string)=>{
    let locationCoords = await Location.geocodeAsync(address);
    if(!locationCoords.length){
        alert("Error verifying your location, try modifying the formatting, or checking for typos");
        return null;
    }
    return locationCoords[0];
}

export default getLatLngFromAddress

