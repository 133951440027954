import { View, Text, TextInput, StyleSheet } from 'react-native';
import { COLORS } from '../Constants';

const Input = (props: any)=>{

    const { defaultValue, onInputChange, style, inputStyle, showLabel=true, label } = props

    return(
        <View style={{width: '100%', marginBottom: 10, ...style}}>
            {showLabel && <Text style={{marginVertical: 8}}>{label}</Text>}
            <TextInput
                defaultValue={defaultValue}
                onChangeText={(value: string)=>onInputChange(value)}
                style={{
                    paddingHorizontal: 2,
                    paddingVertical: 5,
                    borderBottomColor: '#ccc',
                    borderBottomWidth: 1,
                    ...inputStyle
                }}
            />
    </View>
    )
}

const styles = StyleSheet.create({
    style: {

    },

})


export default Input