import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, Platform, ImageBackgroundBase, ImageBackground } from 'react-native';
// import Card from '../../components/Card';
// import Input from '../../components/Input';
import CacheImageBackground from './CacheImageBackground';
// import TouchableCard from '../../components/TouchableCard';
// import { cloudFrontUrl, SCREEN_SIZE, MAX_NUMBER_OF_DATING_PICTURES } from '../../Constants';
// import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from '@expo/vector-icons';
// import { useEffect } from 'react';
// import { connect } from 'react-redux';
// import Animated, { runOnJS, useAnimatedGestureHandler, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
// import { PanGestureHandler } from 'react-native-gesture-handler';
import Fire from '../Fire'
// import deleteFromStorage from '../../helpers/deleteFromStorage';
import UploadPictureModal from './UploadPictureModal';
import { COLORS, S3_URL, SCREEN_SIZE } from '../Constants';
import TouchableContainer from './TouchableContainer';
import getUserDataAsync from '../helpers/getUserDataAsync';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import deleteFromStorage from '../helpers/deleteFromStorage';
import { updateUserDataProperty } from '../store/slices/userData';
import CustomButton from './CustomButton';
import updateUserDatabaseKey from '../helpers/updateUserDatabaseKey';
const firebase = Fire.getFirebase();

const MAX_NUMBER_OF_PICTURES = 15;

const AccountSelectProfilePicture = (props: any)=>{

    const { onPictureSelected, profilePictureKey } = props;

    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);

    const defaultUploadedPictures = (()=>{
        return userData.pictureKeys ? userData.pictureKeys : [];
    })()
    const [uploadedPictures, setUploadedPictures] = useState<any>(defaultUploadedPictures);
    const [ currentIndex, setCurrentIndex ] = useState<any>(null)
    const [ selectedImageKey, setSelectedImageKey ] = useState<string>("")


    useEffect(()=>{
        let ref = firebase.database().ref(`publicUserData/${userData.uid}/`)
        ref.on('value', (data)=>{
            let userData = data.val()
            setUploadedPictures(userData.pictureKeys);
        })
        return () =>{
            ref.off();
        }
    }, [])

    const Picture = (props: any)=>{
        const {pictureKey, index} = props;
        let key = pictureKey
        if(!isNaN(key)) return <></>
        const isCurrentlySelected = currentIndex === index;
        const isProfilePicture = key === profilePictureKey
        return(
            <TouchableContainer
                onPress={()=>{
                    setCurrentIndex(index)
                    setSelectedImageKey(key);
                }}
                key={index}
                style={{
                    borderStyle: 'dashed',
                    borderColor: COLORS.primaryRed,
                    borderRadius: isNaN(key) ? 0 : 1,
                    borderWidth: isNaN(key) ? 0 : 2,
                    backgroundColor: isNaN(key) ? 'transparent' : '#CCCCCC',

                    height: SCREEN_SIZE.height * .20,
                    width: SCREEN_SIZE.height * 0.14,
                    margin: 5
                }}>
                <ImageBackground
                    // key={Date.now()}
                    //@ts-ignore
                    resizeMode={'cover'}
                    //@ts-ignore
                    style={{
                        flex: 1,
                        justifyContent: 'flex-end',
                    }}
                    //@ts-ignore
                    // imageStyle={this.props.imageStyle}
                    // source={require('../assets/alwayslocal-logo.png')}
                    source={isNaN(key) ? `${S3_URL}/${key}` : require('../assets/alwayslocal-logo.png')}
                    // imageRef={((imgBackground)=>{
                    //     imgBackground.queryCache(this.state.source.uri);
                    // })}
                >
                    {
                        (isCurrentlySelected || (isProfilePicture && currentIndex === null)) && 
                            <AntDesign
                                name="checkcircleo"
                                size={30} 
                                color={COLORS.primaryRed}
                                style={{
                                    alignItems: 'center',
                                    alignSelf: 'flex-end',
                                    position: 'relative',
                                    top: '6%',
                                    left: '6%'
                                }}
                            />
                    }
                </ImageBackground>
            </TouchableContainer>
        )
    }


    const onSelectProfilePicture = (event: any)=>{
        event.preventDefault();
        //update database with new profile picture key
        updateUserDatabaseKey(userData.uid, 'profilePictureKey', selectedImageKey)
        //update local data with new profile picture key
        dispatch(updateUserDataProperty({property: 'profilePictureKey', value: selectedImageKey}))
        onPictureSelected();
    }


    const renderUploadedPictures = ()=>{
        let pictureComponents = [];
        for (let index=0; index<MAX_NUMBER_OF_PICTURES; index+=1){
            const key = uploadedPictures[index];
            if(key) pictureComponents.push(<Picture key={key} pictureKey={key} index={index}/>)
            else pictureComponents.push(<Picture key={index} pictureKey={Math.random()} index={index}/>)
        }
        return pictureComponents
    }


    return(
        <View style={{flex: 1, backgroundColor: COLORS.primaryBgColor, borderRadius: 10}}>
            <View key={uploadedPictures} style={{flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', marginBottom: 20}}>
                {renderUploadedPictures()}
            </View>
            <View>
            <CustomButton
                onPress={(e: any)=>onSelectProfilePicture(e)}
                title={
                    <Text style={{
                        color: 'white',
                        fontSize: 16,
                        fontWeight: '500',
                        textAlignVertical: 'center'
                    }}>
                            Set Profile Picture
                    </Text>
                }
                style={{
                    backgroundColor: COLORS.primaryRed,
                    padding: 10,
                    borderRadius: 10,
                    // width: '20%',
                    width: 165,
                    alignSelf: 'center',
                }}
            />
            </View>
        </View>
    )

}

  

export default AccountSelectProfilePicture;
// export default connect(mapStateToProps, null )(DatingChoosePictures);