import { SERVER_URL } from './../Constants/index';
import { pictureBucket, videoBucket } from './../Constants';
import catchErrorForSentry from './catchSentryError';

const getMediaUploadUrl = (imageKey: string, objectType: 'image' | 'video')=>{
    let link = `${SERVER_URL}/getSignedImageUrl`;
    let status: any;
    const dataObj = {Key: imageKey, Bucket: objectType === 'image' ? pictureBucket : videoBucket}
    return fetch(link, {
      method: 'POST',
      headers: {
        // Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataObj)
    })
    .then(res => {
          status = res.status;
          return res.text();
    })
    .then(text => {
        if (status === 200) {
            // console.log("Response: ", text);
            // console.log("Success getting signed url");
            return text;
        } else {
            console.log("Problem with response");
            // alert(`Problem with response: ${status} - ${text}`)
            return "";
            // console.log("else - Response: ", text);
        }
    }).catch(error => {
        console.log("Error getting signed urls: ", error);
        console.log("JSON stringified format: ", JSON.stringify(error)); 
        catchErrorForSentry(error);
        // alert(`Error getting signed urls: ${JSON.stringify(error)}`)
        return "";
    });
}

export default getMediaUploadUrl